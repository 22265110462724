import { HttpContextToken } from "@angular/common/http";

export const APP_CONSTANTS = {
  title: 'ÎLIVOU',
  email: 'hello.ilivou@gmail.com',
  phone: '+337 83 95 22 84',
  whatsAPpBusiness: 'https://wa.me/message/FQBIFUSP5KM5L1',
  whatsAPp: 'https://wa.me/+33783952284',
  default_language: 'fr',
  citiesAvailables: [
    { name: 'Guadeloupe' },
    { name: 'Martinique' },
    { name: 'Guyane' },
    { name: 'Mayotte' },
    { name: 'La Réunion' },
    { name: 'Saint-Barthélemy' },
    { name: 'Saint-Martin' },
    { name: 'Saint-Pierre-et-Miquelon' },
    { name: 'Nouvelle-Calédonie' },
    { name: 'Polynésie française' },
    { name: 'Wallis-et-Futuna' },
    { name: 'Les terres australes et antarctiques françaises' },
  ],
  productLinkDOmain: [
    //faudra faire une boucle after
    {
      label: 'Amazon',
      value: 'amazon',
      link: 'https://www.amazon.fr',
    },

    {
      label: 'Leboncoin',
      value: 'leboncoin',
      link: 'https://www.leboncoin.fr',
    },
    {
      label: 'Oscaro',
      value: 'oscaro',
      link: 'https://www.oscaro.com',
    },
    {
      label: 'Zara',
      value: 'zara',
      link: 'https://www.zara.com/fr/',
    },
    {
      label: 'AliExpress',
      value: 'aliexpress',
      link: 'https://aliexpress.com',
    },
    {
      label: 'Cdiscount',
      value: 'cdiscount',
      link: 'https://www.cdiscount.com',
    },
  ],
  facebookUrl: 'https://www.facebook.com/people/%C3%8Elivou/100094073931746/',
  instagramUrl: 'https://instagram.com/ili.vou?igshid=NGExMmI2YTkyZg==',
  linkedinUrl: 'https://www.linkedin.com/company/ilivou/',
  shoppingStatus: {
    VERIFICATION: { status: 'VER', label: 'En cours de Vérification' },
    PUBLICATION: { status: 'PUB', label: 'Publié' },
    CANCELLATION: { status: 'CAN', label: 'Annulé' },
  },
  paymentStatus: {
    PAID: { status: 'PAI', label: 'payé' },
    NO_PAID: { status: 'NOP', label: 'En cours de paiement' },
  },
  typePayments: {
    WEB: { value: 'WEB', label: 'Paiement direct via le web' },
    CARD: { value: 'CAR', label: 'Paiement avec une carte enregistrée' },
  },
  ExtrasCommonChoices: {
    ABOUT: {
      title: 'ABO',
      lable: 'A propos',
    },
    PRIVACY: {
      title: 'PRI',
      lable: 'Politique de confidentialité',
    },
    TERMS_OF_USE: {
      title: 'TOU',
      lable: 'CGU',
    },
    FAQ: {
      title: 'FAQ',
      lable: 'FAQ',
    },
    LEGAL_NOTICE: {
      title: 'LNO',
      lable: 'Mentions légales',
    },
    HOW_IT_WORKS: {
      title: 'HIW',
      lable: 'Comment ça marche',
    },
    COOKIES: {
      title: 'COO',
      lable: 'Politique de cookies',
    },
  },
};


export const HAS_NEED_TOKEN = new HttpContextToken(() => false);//pour savoir si l'url doit attacher ou pas le token
export const IS_FROM_GUARDS = new HttpContextToken(() => false);//si ça vient de app ou du guards pour pas appliquer les mêmes gestions d'erreurs

export const DELIEVERIES_ORDERS_LAST10 = [
  {
    image: '../../../../../assets/images/products/product4.png',
    to_city: 'La Réunion',
    from_city: 'France',
    saved: '30€',
    earned: '24€',
    shopper_avatar: '../../../../../assets/images/avatar/avatar1.png',
    traveler_avatar: '../../../../../assets/images/avatar/avatar2.png',
  },
  {
    image: '../../../../../assets/images/products/product2.png',
    to_city: 'Martinique',
    from_city: 'France',
    saved: '17€',
    earned: '12€',
    shopper_avatar: '../../../../../assets/images/avatar/avatar3.png',
    traveler_avatar: '../../../../../assets/images/avatar/avatar8.png',
  },
  {
    image: '../../../../../assets/images/products/product3.jpg',
    to_city: 'Nouvelle-Calédonie',
    from_city: 'France',
    saved: '63€',
    earned: '39€',
    shopper_avatar: '../../../../../assets/images/avatar/avatar5.png',
    traveler_avatar: '../../../../../assets/images/avatar/avatar6.png',
  },

];