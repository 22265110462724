import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { LoginService } from 'src/app/services/login-service/login.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  isLogin = false;
  form: FormGroup;
  environment = environment;
  @Input() fromParentIsModal: boolean = false;
  dataFromParent = 'LoginFormComponent';

  redirectURL: string;
  params: any = '';
  connectSuccess;

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private apiService: ApiService,
    private modalService: ModalService,
    private router: Router,
    private datawarehouseService: DatawarehouseService,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.connectSuccess = this.translateService.instant('connectSuccess');
  }

  ngOnInit() {
    this.getForm();
    this.loginService.getfromParentData$.subscribe((d) => {
      if (d && d['route'] && d['route']['redirectURL']) {
        this.redirectURL = d['route']['redirectURL'];
      }
    });
  }

  ionViewWillEnter() {
    this.getForm();
  }
  ionViewDidEnter() {
  }

  getForm() {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.utilsService.pwdPattern),
        Validators.maxLength(150),
        this.utilsService.noWhitespaceValidator,
      ]),
    });
  }
  send() {
    const value = this.form.value;
    this.apiService.login(value).subscribe((x) => {

      if (x && x['user'] && x['user']['email']) {
        const token = {
          access: x['access'],
          refresh: x['refresh'],
        };
        this.localStorageService.setToken('token', token);
        this.localStorageService.setWsToken('ws_token', x['ws_token']);
        this.datawarehouseService.setloginData(x);

        if (this.router.url.includes('error')) {
          //si erreur
          this.router.navigate(['home']);
        } else {
          this.toastService.presentToast('top', this.connectSuccess, 'success');

          if (!this.fromParentIsModal) {
            //si c'est pas modal ==> alors on redirige vers account car sinon aller directement sur user profile ne declenche pas ionViewnit
            this.router.navigate(['account']);
          } else {
            this.cancel();
          }
        }
      }
    });
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }

  signup() {
    this.router.navigate(['/signup']);
  }
  resetPassword() {
    this.router.navigate(['/reset-password']);
  }
  getErrorMessage(ctrl) {
    return this.utilsService.getErrorMessage(ctrl, this.form);
  }
  ionViewWillLeave() {
  }
}
