import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-modal-search-product',
  templateUrl: './modal-search-product.component.html',
  styleUrls: ['./modal-search-product.component.scss'],
})
export class ModalSearchProductComponent implements OnInit {
  modalData;
  constructor(private modalService: ModalService) {}

  ngOnInit() {
  }
  go(){
    return this.modalService.confirm(this.modalData);
    
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
}
