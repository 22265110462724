import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-modal-convince',
  templateUrl: './modal-convince.component.html',
  styleUrls: ['./modal-convince.component.scss'],
})
export class ModalConvinceComponent implements OnInit {
  modalData;
  whatsAPpBusiness = APP_CONSTANTS.whatsAPpBusiness;
  whatsAPp = APP_CONSTANTS.whatsAPp;
  current_url=""
  constructor(private modalService: ModalService, private router:Router) {}

  ngOnInit() {
    console.log(this.modalData);
    console.log(this.router.url);
    
  }
 
  openWhatsAppTraveler() {
    this.current_url = this.router.url.slice(19);
    const message =
      'Objet%3AVoyageur%0ANom%20du%20Voyageur%3A%0ACommande%3A' +
      this.current_url; ;
    window.open(this.whatsAPp + '?text=' + message, '_blank');
  }
  openWhatsAppShopper() {
    this.current_url = encodeURI(this.modalData['link']);
    const to_city = encodeURI(this.modalData['to_city']);
    const message =
      'Objet%3AAchat%0AVotre%20nom%3A%0ALieu%20de%20livraison%3A'+ to_city+'%0ACommande%3A' +
      this.current_url; ;
    window.open(this.whatsAPp + '?text=' + message, '_blank');
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  confirm() {
    //pour fermer la modal
    return this.modalService.confirm({});
  }
}
