import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PhotoService } from 'src/app/services/photo-service/photo.service';
import { TravelFormService } from 'src/app/services/travel-form-service/travel-form.service';
import { TravelFormComponent } from '../shared/travel-form/travel-form.component';

import { Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { HandleDateService } from 'src/app/services/date-handle-service/handle-date.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { environment } from 'src/environments/environment';
import { ModalLoginSignupComponent } from '../shared/modal-login-signup/modal-login-signup.component';
import { ModalSpinnerComponent } from '../shared/modal-spinner/modal-spinner.component';
@Component({
  selector: 'app-travel',
  templateUrl: './travel.page.html',
  styleUrls: ['./travel.page.scss'],
})
export class TravelPage implements OnInit {
  currentRate = 8;
  @ViewChild(TravelFormComponent)
  travelFormComponent: TravelFormComponent;
  translateData;
  @ViewChild(IonContent) private content: IonContent;
  @Input() fromDialogParent: any; // si ça vient de dialogTravelForm
  @Input() dataTravelItem: any; // si ça vient de dialogTravelForm
  @Output() formEvent = new EventEmitter<any>();


  formInvalid = true;
  currentUrlFromInAppBrowser = ''; //pour les tests
  isDev = environment.production;
  displayTravelFrom = true;

  constructor(
    private photoService: PhotoService,
    private travelFormService: TravelFormService,
    private translateService: TranslateService,
    private handleDateService: HandleDateService,
    private toastService: ToastService,
    private datawarehouseService: DatawarehouseService,
    private modalService: ModalService,
    private apiService: ApiService,
    private router: Router,
  ) {}
  spinner(){
     this.modalService.openModalSpinner(ModalSpinnerComponent, {}).then()
  }

  ngOnInit() {
    this.init();
  }
  init() {
    this.translateService.get('travelForm.travelPage').subscribe((t) => {
      this.translateData = t;
      this.setFromParentComponentForTravelForm();
    });

    this.resetForm();
  }
  resetForm() {
    //permet avec ng-if de reset le form sans trop se prendre la tête
    this.displayTravelFrom = false;
    setTimeout(() => {
      this.displayTravelFrom = true;
    }, 500);
  }
  ionViewWillEnter() {
    this.init();
    this.content.scrollToTop();
  }
  setFromParentComponentForTravelForm() {
    this.travelFormService.setfromParentData({
      fromParentComponent: 'TravelPage',
      metaDataTranslation: this.translateData,
      travel_date: this.handleDateService.currentDatePlus1Day,
    });
  }
  addPhotoToGallery() {
    this.photoService
      .addNewToGallery()
      .then((value) => {
      })
      .catch((e) => {
      });
  }
  formTravelEvent(event) {
    this.formInvalid = event.invalid;
  }
  send() {
    const values = this.travelFormComponent.form.value;
    let formData = new FormData();
    formData.append('from_city', values['from_city']['id']);
    formData.append('to_city', values['to_city']['id']);
    formData.append(
      'travel_date',
      this.handleDateService.getDateToString(values['travel_date'])
    );
    const translationALert = this.translateService.instant(
      'travelForm.alertSameDestination'
    );
    const msg = translationALert['msg'];
    const confirmText = translationALert['confirmText'];
    const cancelText = translationALert['cancelText'];
    if (values['from_city']['id'] == values['to_city']['id']) {
      this.toastService
        .presentAlert(msg, cancelText, confirmText)
        .then((res) => {
          if (res == 'confirm') {
            this.sendTravel(formData);
          }
        });
    } else {
      this.sendTravel(formData);
    }
  }
  sendTravel(formData) {
    const loginData = this.datawarehouseService.currentLoginData();
    if (loginData && loginData['user']) {
      //si il y a un token
      this.apiService.postTravels(formData).subscribe((d) => {
        if (d && d['uuid']) {
          if (this.fromDialogParent != 'DialogTravelFormComponent') {
            //pour permettre de ne pas changer de page DialogTravelFormComponent
            this.router.navigate(['send-ok/']);
          } else {
            this.formEvent.emit(d);
          }
        }
      });
    } else {
      //si pas de token
      this.modalService.openModal(ModalLoginSignupComponent, {
        isLogin: false,
      });
    }
  }

  ionViewWillLeave() {
    if (this.travelFormComponent) {
      this.travelFormComponent.travelFormServiceSusbscription.unsubscribe();
    }
  }
  fireClientError() {
    throw new Error('My Pretty Error');
  }
  loadingTest() {
  
  }
}
