<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
        <ion-buttons slot="end">
      <ion-button (click)="login()" *ngIf="isLogin" fill="outline">
        {{ "signin" | translate}}
      </ion-button>
      <ion-button (click)="signup()" *ngIf="!isLogin" fill="outline">
        {{ "registration" | translate}}
      </ion-button>
        </ion-buttons>
  </ion-toolbar>

</ion-header>
<div [ngStyle]="{'height': '100%'}">
  <ion-content>
    <div *ngIf="!isLogin">
        <div class="ion-text-right" >
          <ion-chip [outline]="true" (click)="signup()" >{{"not_account" | translate | capitalize}}
          </ion-chip>
          <mat-divider></mat-divider>
        
        </div>
      <app-login-form [fromParentIsModal]="fromParentIsModal"></app-login-form >
    </div>
    <div *ngIf="isLogin" >
          <div class="ion-text-right" >
            <ion-chip [outline]="true" (click)="login()" >{{ "signin" | translate}}
            </ion-chip>
            <mat-divider></mat-divider>
          
          </div>
      <app-sign-up-form [fromParentIsModal]="fromParentIsModal"></app-sign-up-form>
    </div>
    
  </ion-content>
</div>
