<div class="ion-text-center">
    <ion-label >{{name | uppercase}}</ion-label>

</div>   

<ion-item button detail="true" (click)="going('account/profile')">
    <ion-label>
        {{"account.userProfil.title" | translate | capitalize}}
    </ion-label>
</ion-item>
<ion-item button detail="true" (click)="going('account/shoppings')">
    <ion-label>
        {{"account.userShoppings.title" | translate | capitalize}}
    </ion-label>
</ion-item>
<ion-item button detail="true" (click)="going('account/travels')">
    <ion-label>
        {{"account.userTravels.title" | translate | capitalize}}
    </ion-label>
</ion-item>
<ion-item button detail="true" (click)="going('account/payout')">
    <!-- <ion-icon aria-hidden="true" name="alert-sharp" slot="end" color="danger" *ngIf="details_submitted"></ion-icon> -->
    <ion-label>
        {{"account.userPayouts.title" | translate | capitalize}} <span [ngStyle]="{'font-weight': '500'}"></span>
        
    </ion-label>

</ion-item>
<ion-item button detail="true" (click)="going('account/settings')">
    <ion-label>
        {{"account.userSettings.title" | translate | capitalize}}
        
    </ion-label>
</ion-item>

<ion-item button color="danger"  (click)="logout()">
    <ion-label>
        {{"account.logout" | translate | capitalize}}

    </ion-label>
</ion-item>
