import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss'],
})
export class StripeConnectComponent implements OnInit {
  //*************voir comment surpprimer ce composant */
  modalData; //cela vient du modal voir le modalService

  constructor(private modalService: ModalService) {}

  ngOnInit() {}

  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  confirm() {
    return this.modalService.confirm({});
  }
}
