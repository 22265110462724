import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js';


if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () {};
  }
}

posthog.init(environment.ph_project_api_key, {
  api_host: 'https://app.posthog.com', // usually 'https://app.posthog.com' or 'https://eu.posthog.com'
  capture_pageview: false,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

defineCustomElements(window);