import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss'],
})
export class ModalSpinnerComponent implements OnInit {
  modalData;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private datawarehouseService: DatawarehouseService
  ) {}

  ngOnInit() {
    console.log(this.modalData);
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  manualyEntry() {
    let data ={}
    data['product_link'] = this.modalData;
    this.datawarehouseService.setorderData(data);
    this.router.navigate(['order']);
    this.cancel();
  }
}
