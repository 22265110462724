<ion-content>
  <ion-grid fixed class="my-container-500 ">
    <div class="arr">
      <ion-icon name="arrow-back-outline" size="large" (click)="back()"></ion-icon>
    </div>


    <div *ngIf="userData">
      <ion-item>
        <ion-avatar slot="start">
          <img alt="avatar" src="{{userData['avatar']}}" [appPhotoMissing]="'missing-avatar.png'" />
        </ion-avatar>
        <ion-label>{{userData['first_name']}} {{userData['last_name']}}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-chip>
          <ion-icon name="star"></ion-icon>
          <ion-label>{{userData['rating']}}/5</ion-label>
        </ion-chip>
        <ion-chip>
          <ion-label>{{userData['review_receiver_user'].length}}
            {{"ads.card.reviews" | translate | lowercase}}

          </ion-label>
        </ion-chip>
      </ion-item>
    <ion-item lines="none" *ngIf="userData['description']">
      <ion-note >
{{userData['description']}}
      </ion-note>
    </ion-item>
      <ion-list>
        <ion-item>
          <ion-label fxLayout="row" fxLayoutAlign="space-between center">
            <div>{{"userDetails.checkEmail" | translate | capitalize}}</div>
            <div>
              <ion-icon name="checkmark-circle-outline" color="success" size="large"
                *ngIf="userData['check_data']['check_email']">
              </ion-icon>
              <ion-icon name="close-circle-outline" color="danger" size="large"
                *ngIf="!userData['check_data']['check_email']">
              </ion-icon>
            </div>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label >
            <div>{{"userDetails.lastLogin" | translate | capitalize}}</div>
            <div class="ion-text-end">
              {{userData['check_data']['last_login'] | date:'longDate'}} -
              {{userData['check_data']['last_login'] | date:'HH:mm:ss':'+0:00'}}
            </div>

          </ion-label>
        </ion-item>
      </ion-list>
    </div>


    <mat-tab-group [ngStyle]="{'margin-top': '30px'}" (selectedTabChange)="selectedTabChange($event)"
      [(selectedIndex)]="selectedIndex" *ngIf="userData">
      <mat-tab label='{{"userDetails.reviews" | translate | capitalize}}'>
        <h3 [ngStyle]="{'margin-top': '15px'}">{{userData['review_receiver_user'].length}} {{"ads.card.reviews" |
          translate | lowercase}}
        </h3>

        <ion-card [ngStyle]="{'margin-bottom': '5px'}" class="card"
          *ngFor="let review of userData['review_receiver_user']">
          <ion-item>
            <ion-avatar slot="start">
              <img alt="avatar" src="{{review['avatar']}}" [appPhotoMissing]="'missing-avatar.png'" />
            </ion-avatar>
            <ion-label>{{review['first_name']}} {{review['last_name']}}</ion-label>
          </ion-item>

          <ion-card-content>
            <ion-chip>
              <ion-icon name="star"></ion-icon>
              <ion-label>{{review['rating']}}/5</ion-label>
            </ion-chip><br>
            {{review['comments']}}
          </ion-card-content>
          <ion-footer [ngStyle]="{'padding': '16px'}">
            {{"userDetails.toPost" | translate | capitalize}} {{review['created_at'] | date:'longDate'}}
          </ion-footer>

        </ion-card>


      </mat-tab>
      <mat-tab label='{{"userDetails.orders" | translate | capitalize}}'>
        <h3 [ngStyle]="{'margin-top': '15px'}">{{shoppingData.length}} {{"userDetails.orders" | translate | lowercase}}
        </h3>

        <app-shopping-card [shoppingData]="shoppingData" [fromComponent]="componentName"></app-shopping-card>
      </mat-tab>
      <mat-tab label='{{"userDetails.travels" | translate | capitalize}}'>
        <h3 [ngStyle]="{'margin-top': '15px'}">{{travelData.length}} {{"userDetails.travelsCaming" | translate |
          lowercase}}
        </h3>
        <app-travel-card [travelData]="travelData" [fromComponent]="componentName"></app-travel-card>


      </mat-tab>
    </mat-tab-group>
  </ion-grid>
</ion-content>