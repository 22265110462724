<ion-content>


      <h3 class="ion-text-center" [ngStyle]="{'margin': '30px'}">{{"travel.title" | translate }}</h3>

      <ion-grid fixed class="my-container-500 ">
  <app-travel-form (formEvent)="formTravelEvent($event)" *ngIf="displayTravelFrom" [dataTravelItem]="dataTravelItem" [dataFromParentComponent]="fromDialogParent"></app-travel-form>
   <div class="ion-text-end">
      <ion-button color="primary" [disabled]="formInvalid" (click)="send()">
        {{"toSend" | translate }}
      </ion-button>
   </div >

</ion-grid>

</ion-content>



