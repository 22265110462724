import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ModalLoginSignupComponent } from 'src/app/components/shared/modal-login-signup/modal-login-signup.component';
import { StripeConnectComponent } from 'src/app/components/shared/stripe/stripe-connect/stripe-connect.component';
import {
  HAS_NEED_TOKEN,
  IS_FROM_GUARDS
} from 'src/app/constants/app.constants';
import { ApiService } from '../api-service/api.service';
import { DatawarehouseService } from '../datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { ModalService } from '../modal-service/modal.service';
import { SocialAuthService } from '../social-auth-service/social-auth.service';
import { ToastService } from '../toast-service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  translateData;

  constructor(
    private router: Router,
    private datawarehouseService: DatawarehouseService,
    private toastService: ToastService,
    private modalService: ModalService,
    private storage: Storage, // on est obligé d'utiliser ça au lieu de localStorageService snon ça ne marche pas
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private socialAuthService: SocialAuthService
  ) {
    this.datawarehouseService.getTranslateData$.subscribe((t) => {
      this.translateData = t;
    });
  }
  storageToken(key, value) {
    this.localStorageService.settokenData(value);
    this.storage.set(key, value);
  }
  login() {
    this.apiService.logoutCommon();
    this.router.navigate(['/login'], {
      queryParams: { redirectURL: this.router.url },
    });
  }
  addTokenHeader(request: HttpRequest<any>, currentToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${currentToken}`,
      },
    });
  }
  refreshToken(token, next, request): Observable<HttpEvent<any>> {
    return this.apiService.refreshToken(token).pipe(
      switchMap((current_token: any) => {
        if (current_token && current_token['access']) {
          const new_token = {
            access: current_token['access'], //nouveau access token
            refresh: token, // on garde toujrous le refresh pour le prochain rafraichissement jusqu"a son expriation
          };

          // this.storage.set('token', new_token);
          this.storageToken('token', new_token);
          return next.handle(
            this.addTokenHeader(request, current_token['access'])
          );
        } else {
          //sinon logout
          return of('');
        }
      })
    ) as Observable<HttpEvent<any>>;
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storage.get('token')).pipe(
      switchMap((token) => {
        const has_need_token = request.context.get(HAS_NEED_TOKEN);
        if (token) {
          const currentToken = token['access'] ? token['access'] : null;
          const refresh_token = token['refresh'] ? token['refresh'] : null;
          if (has_need_token == true) {
            // si besoin de token
            if (currentToken != null && currentToken != undefined) {
              request = this.addTokenHeader(request, currentToken);
            }
          }
        }
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error instanceof HttpErrorResponse) {
              errorMessage = `Error: ${error}`;
              switch (error.status) {
                case 0:
                  return this.handle0Error(error, request);
                case 400:
                  return this.handle400Error(error, request);
                case 401:
                  return this.handle401Error(error, request, next, token);
                case 403:
                  return this.handle403Error(error, request);
                case 404:
                  return this.handle404Error(error, request);
                case 500:
                  return this.handle500Error(error, request);
                case 503:
                  return this.handle503Error(error, request);
                default:
                  return this.handleError(error, request);
              }
            }
          })
        );
      })
    );
  }
  handle0Error(error, request) {
    //backend innaccessible
    //retour vers page de maintenance

    return throwError(error);
  }
  handle403Error(error, request) {
    if (request.url.includes('api/users/activation/')) {
      this.router.navigate(['/send-ok'], {
        queryParams: { type: 'activationIsOk' },
      });
    } else {
      if (error.error.detail) {
        this.toastService.presentToast('top', error.error.detail, 'danger');
      } else {
        const msg = this.translateData['403Error'];
        this.toastService.presentToast('top', msg, 'danger');
      }
    }
    return throwError(error);
  }
  handle404Error(error, request) {
    if (request.url.includes('api/offer/message')) {
      this.datawarehouseService.setcountMessageData(1);
      this.router.navigate(['/messages']);
    } else {
      this.router.navigate(['/']);
      this.global404Error(error.error);
    }
    return throwError(error);
  }
  handle401Error(error, request, next, token) {
    if (request.url.includes('api/users/login')) {
      //erreur en voulant se connecter(mot de pass ou email incorrecte)
      this.loginError(error.error);
    } else if (
      error.error.code == 'token_not_valid' &&
      error.error.detail == 'Given token not valid for any token type'
    ) {
      //refresh token en silencieux
      return this.refreshToken(token['refresh'], next, request);
    } else if (
      error.error.code == 'token_not_valid' &&
      error.error.detail == 'Token is invalid or expired'
    ) {
      //refresh token est exipiré et  il faut faire un logout pour qu'il se connecte
  
      if (request.context.get(IS_FROM_GUARDS)) {
        //si ça vient du guards, on redirige vers l'auth
        this.apiService.logoutRedirectAccount();
        //pour l'instant on rentre jamais ici car on l'utilisa pas dans guards
      } else {
        //on est ici car on est sur une page qui n'a pas besoin d'authentification comme le home par exemple
        //on efface le token
        this.apiService.logoutRedirectAccount();
      }
    } else {
      //erreur  un token invalide ou pas de token avec un retour du back "Informations d'authentification non fournies."
      //"Informations d'authentification non fournies."
      //quand le refresh du token a echoué
      // this.apiService.logoutRedirectAccount();
      this.modalService.openModal(ModalLoginSignupComponent, {
        isLogin: false,
      });
      this.datawarehouseService.setloginData(null);
    }
    return throwError(error);
  }
  handle400Error(error, request) {
    if (request.url.includes('api/users/retrieve-update-data/')) {
      //utilisé dans user-profile
      this.retrieveUpdateError(error.error);
    }
    if (request.url.includes('api/users/activation/')) {
      //utilisé dans user-activation
      this.activationError(error.error);
    } else if (request.url.includes('api/users/reset_password/')) {
      this.resetPasswordError(error.error);
    } else if (request.url.includes('api/users/reset_password_confirm/')) {
      this.confirmPasswordError(error.error);
    } else if (request.url.includes('api/users/iban/registration/')) {
      this.global404Error(error.error);
    } else if (request.url.includes('api/users/firebase/auth/')) {
      try {
        this.socialAuthService.signOut();
      } catch (error) {
      }
      this.global404Error(error.error);
    } else if (request.url.includes('api/users/')) {
      //il faut le mettre en dernière position de toutes les urls commencans par api/users
      //insrcription
      this.signUpError(error.error);
    } else if (request.url.includes('api/gniparcs/search-item/')) {
      //gestion des erreurs du scraping
      this.scrapingError(error.error);
    } else if (request.url.includes('api/shopping/list-create/')) {
      //gestion des erreurs quand on poste un shopping
      //les erreurs comme max_length de product_title, image non base64
      this.shoppingError(error.error);
    } else if (request.url.includes('api/offer/list-create/')) {
      //gestion des erreurs quand on poste un offer
      this.offerError(error.error);
    } else if (request.url.includes('api/delivery/detail/')) {
      //gestion des erreurs quand on envoit le code de cpnfirmation
      this.confirmCodeError(error.error);
    } 
    else if (request.url.includes('/api/shopping/detail/')) {
      //gestion des erreurs quand on veut supprimer une annonce
      this.shoppingError(error.error);
    } 
    else if (request.url.includes('api/shopping/report-abus/')) {
      //gestion des erreurs quand on veut supprimer une annonce
      this.reportAbusError(error.error);
    } else if (request.url.includes('api/my-alerts-travelss/update/')) {
      //gestion des erreurs alerts
      this.shoppingError(error.error);
    } else {
      this.global404Error(error.error);
    }
    return throwError(error);
  }
  handle503Error(error, request) {
    //page de maintenance
    return throwError(error);
  }
  handle500Error(error, request) {
    //supprimer le token
    this.router.navigate(['/error']);

    return throwError(error);
  }
  handleError(error, request) {
    return throwError(error);
  }

  //***********************************signup */
  signUpError(error) {
    //inscription
    const data = Object.entries(this.translateData['signup']['form']);
    let msg = '';
    data.forEach((d) => {
      if (error[d[0]]) {
        //exemple de error {last_name: Array(1), email: Array(1)}
        // email: ['Un objet Utilisateur avec ce champ Email existe déjà.'];
        //last_name: ['Ce champ est obligatoire.'];
        const label = d[1]['label'];
        msg += label + ' : ' + error[d[0]][0] + '<br>';
      }
    });
    this.toastService.presentToast('top', msg, 'danger');
  }
  //**********************************login***************** */
  loginError(error) {
    //inscription
    const msg = this.translateData['login']['cannotLogin'];
    this.toastService.presentToast('top', msg, 'danger');
  }
  //*******************************retrive update error******** */
  retrieveUpdateError(error) {
    //utilisé dans account
    const data = Object.entries(
      this.translateData['account']['userProfil']['form']
    );
    let msg = '';
    data.forEach((d) => {
      if (error[d[0]]) {
        //exemple de error {last_name: Array(1), email: Array(1)}
        // email: ['Un objet Utilisateur avec ce champ Email existe déjà.'];
        //last_name: ['Ce champ est obligatoire.'];
        const label = d[1]['label'];
        msg += label + ' : ' + error[d[0]][0] + '<br>';
      }
    });
    this.toastService.presentToast('top', msg, 'danger');
  }
  activationError(error) {

    const msg = this.translateData['activationError'];
    this.toastService.presentToast('top', msg, 'danger');
  }
  resetPasswordError(error) {
    if (error['email']) {
      const msg = error['email'][0];
      this.toastService.presentToast('top', msg, 'danger');
    } 
    else if (error['non_field_errors']){
         const msg = error['non_field_errors'][0];
         this.toastService.presentToast('top', msg, 'danger');
    }
    else {
      const msg = error[0];
      this.toastService.presentToast('top', msg, 'danger');
    }
  }
  confirmPasswordError(error) {
    if (error['token']) {
      const msg = error['token'][0];
      this.toastService.presentToast('top', msg, 'danger');
    } else if (error['uid']) {
      const msg = error['uid'][0];
      this.toastService.presentToast('top', msg, 'danger');
    } else {
      const msg = error[0];
      this.toastService.presentToast('top', msg, 'danger');
    }
  }

  //******************************scraping********************* */
  scrapingError(error) {
    //utilisé dans ShoppindProductDetails - baseRefresh
    if (error && error['detail']) {
      // this.router.navigate(['order']);
      setTimeout(() => {
        this.toastService.presentToast('top', error['detail'], 'danger');
        if (this.router.url != '/order') {
          if (error['url']) {
            this.router.navigate(['manualy-shopping'], {
              queryParams: { url: error['url'] },
            });
          } else {
            this.router.navigate(['manualy-shopping']);
          }
        }
        this.modalService.finish();
        // this.toastService.showLoadingFalse();
      }, 3000);
    }
  }
  //******************************offer********************* */
  offerError(error) {
    if (error && error['detail']) {
      this.toastService.presentToast('top', error['detail'], 'danger');
    }
    if (error && error['non_field_errors']) {
      if (
        error['non_field_errors'][0] &&
        error['non_field_errors'][0] == 'stripe_connect_onboarding_incomplete'
      ) {
        // on redirige le user vers le onboarding stripe
        return this.modalService
          .openModal(StripeConnectComponent, {})
          .then((resp) => {
            if (resp && resp['role'] == 'confirm') {
              // exemple de   "return_url": "https://ilivou.com/single-ad/details/57c7be8d-06a4-4d2b-913f-acc16e81a9e2/?stripe=true",
              const return_url = this.router.url + '/?stripe=true';
              this.createStripeAcoountLink(return_url);
            }
          });
      } else {
        this.toastService.presentToast(
          'top',
          error['non_field_errors'],
          'danger'
        );
      }
    }
  }
  global404Error(error) {
    if (error && error['detail']) {
      this.toastService.presentToast('top', error['detail'], 'danger');
    }
    if (error && error['non_field_errors']) {
      this.toastService.presentToast(
        'top',
        error['non_field_errors'],
        'danger'
      );
    }
  }
  //******************************offer********************* */
  shoppingError(error) {
    if (error && error['detail']) {
      this.toastService.presentToast('top', error['detail'], 'danger');
    }
    if (error && error['non_field_errors']) {
      this.toastService.presentToast(
        'top',
        error['non_field_errors'],
        'danger'
      );
    }
  }
  reportAbusError(error) {
    const msg = this.translateData['errorReportAbus'];
    this.toastService.presentToast('top', msg, 'danger');
  }
  confirmCodeError(error) {
    if (error && error['detail']) {
      this.toastService.presentToast('top', error['detail'], 'danger');
    }
    if (error && error['non_field_errors']) {
      this.toastService.presentToast(
        'top',
        error['non_field_errors'],
        'danger'
      );
    }
  }
  createStripeAcoountLink(returnl_url) {
    this.apiService.postStripeAccountLink(returnl_url).subscribe((resp) => {
      if (resp) {
        if (resp['url']) {
          //On redirige le user pour finaliser son inscription
          window.open(resp['url'], '_self');
        } 
      }
    });
  }
}
