import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { ConfirmDeliveryComponent } from '../confirm-delivery/confirm-delivery.component';
import { OfferDetailsComponent } from '../offer-details/offer-details.component';
import { ReviewsComponent } from '../reviews/reviews.component';

@Component({
  selector: 'app-shopping-card',
  templateUrl: './shopping-card.component.html',
  styleUrls: ['./shopping-card.component.scss'],
})
export class ShoppingCardComponent implements OnInit {
  // shoppingData = [];
  @Input() shoppingData = [];
  @Input() fromComponent;
  @Input() status = 'pub';
  p: number = 1;
  info$ = this.translateService.get('ads.card.totalPriceInfoBulle');
  world_counrties_flags = '../../../../assets/world-countries/flags/';
  @Output() formEvent = new EventEmitter<any>();

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private datawarehouseService: DatawarehouseService,
    private modalService: ModalService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
   
  }
  goToDetails(shoppingUuid) {
    //voir l'annonce
    this.router.navigate(['single-ad/details/' + shoppingUuid + '/']);
  }
  goToOffer(item) {
    //voir les offres
    this.router.navigate(['account/offers'], {
      queryParams: { type: 'shopping', id: item.id, status: this.status },
    });
  }
  displayTooltip() {
    const msg = this.translateService.instant('ads.card.totalPriceInfoBulle');
    this.toastService.presentPopover(msg);
  }
  orderDetails(item) {
    let ii = item['others']['offer'];
    ii['product_purchased'] = item['others']['product_purchased'];
    this.modalService
      .openModal(OfferDetailsComponent, {
        data: ii,
        fromComponent: this.fromComponent,
      })
      .then((resp) => {
        if (resp && resp['role'] == 'confirm') {
        }
      });
  }
  displayingCode(item) {
    //quand le shopper confirme la réception du produit
    // une popop s'ouvre pour lui montrer la modal
    this.modalService
      .openModal(ConfirmDeliveryComponent, {
        data: item,
        fromComponent: 'displayingCode',
      })
      .then((resp) => {
        if (resp && resp['role'] == 'confirm') {
          if (resp['data'] && resp['data']['codeGiven']) {
            //quand le shopper donne le code
            const msg = this.translateService.instant('codeGiven');
            this.toastService.presentToast('top', msg, 'success');
            this.router.navigate(['account']);
          }
        }
      });
  }
  sendingCode(item) {
    //quand le shopper confirme la réception du produit
    // pour envoyer le code de confirmation
    this.modalService
      .openModal(ConfirmDeliveryComponent, {
        data: item,
        fromComponent: 'sendingCode',
      })
      .then((resp) => {
        if (resp && resp['role'] == 'confirm') {
          const code = resp['data']['code'];
          const delivery_uuid = resp['data']['delivery_uuid'];
          const status = resp['data']['status'];
          this.apiService
            .confirmDeliveryCode(code, delivery_uuid, status)
            .subscribe((x) => {
              if (x && x['delivery_uuid']) {
                const msg = this.translateService.instant('deliverySuccess');
                this.toastService.presentToast('top', msg, 'success');
                this.emitValue('DEL');
              }
            });
        }
      });
  }
  emitValue(value) {
    this.formEvent.emit({ status: value });
  }
  deleteShopping(item) {
    const msg = this.translateService.instant('areYouSur');
    const cancelText = this.translateService.instant('cancel');
    const confirmText = this.translateService.instant('yes');
    this.toastService
      .presentAlert(msg, cancelText, confirmText)
      .then((resp) => {
        const msg = this.translateService.instant('shoppingDeleted');
        if (resp == 'confirm') {
          this.apiService.deleteShoppings(item['uuid']).subscribe((x) => {
            if (x == null) {
              this.toastService.presentToast('top', msg, 'success');
              this.router.navigate(['account']);
            }
          });
        }
      });
  }
  cancelDelivery(item) {
    //s annuler la livraison
    const msg = this.translateService.instant('areYouSur');
    const cancelText = this.translateService.instant('cancel');
    const confirmText = this.translateService.instant('yes');
    this.toastService
      .presentAlert(msg, cancelText, confirmText)
      .then((resp) => {
        const msg = this.translateService.instant('offerData.cancelDelivery');
        if (resp == 'confirm') {
          this.apiService
            .cancelDelivery(item['delivery_uuid'])
            .subscribe((x) => {

              if (x && x['delivery_uuid']) {
                this.toastService.presentToast('top', msg, 'light');
                this.router.navigate(['account']);
              }
            });
        }
      });
  }
  messageOffer(item) {
    const offer_uuid = item['others']['offer']['offer_uuid'];
    this.router.navigate(['/messages/chat/' + offer_uuid]);
  }
  userProfile(user_uuid) {
    this.router.navigate(['user/details/' + user_uuid + '/']);
  }
  deliveryBy(user_uuid) {
    this.router.navigate(['user/details/' + user_uuid + '/']);
  }
  reviewModal(data) {
    this.modalService.openModal(ReviewsComponent, data).then((resp) => {
      if (resp && resp['role'] == 'confirm') {
      }
    });
  }
  sendAnReviewByTraveler(item) {
    const delivery_uuid = item['delivery_uuid'];
    this.reviewModal({
      delivery_uuid: delivery_uuid,
    });
  }
  sendAnReviewByShopper(item) {
    const delivery_uuid = item['others']['delivery_order'][0]['delivery_uuid'];
    this.reviewModal({
      delivery_uuid: delivery_uuid,
    });
  }
  instantaneaous(item) {
    const msg = this.translateService.instant('instantaneaousMessage');
    const confirmText = this.translateService.instant('toClose');
    this.toastService.presentAlert(msg, '', confirmText);
  }
  refund(item) {
  }
  payoutShopperDetails(item) {
    let ii = item['others']['offer'];
    this.modalService
      .openModal(OfferDetailsComponent, {
        data: ii,
        fromComponent: this.fromComponent,
      })
      .then((resp) => {
        if (resp && resp['role'] == 'confirm') {
        }
      });
  }
  payoutTravelerDetails(item) {
    let ii = item['others']['delivery']['order']['offer'];
    this.modalService
      .openModal(OfferDetailsComponent, {
        data: ii,
        fromComponent: this.fromComponent,
      })
      .then((resp) => {
        if (resp && resp['role'] == 'confirm') {
        }
      });
  }
  toRePublish(item){    
  }
}
