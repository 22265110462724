<form (ngSubmit)="onSubmit()" [formGroup]="form" #f="ngForm" class="my-form">
  <div class="form-container">
        <div fxLayout="row" fxLayoutAlign="end end">
          <button mat-mini-fab color="primary" aria-label="Icon " (click)="swap()">
            <mat-icon>swap_vert</mat-icon>
          </button>
        
        </div>
    <div class="w-100">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">{{
          metaDataTranslation["from_city"]["label"] | capitalize
          }}</mat-label>
        <input matInput placeholder="{{ metaDataTranslation['from_city']['placeholder'] }}" type="text"
          formControlName="from_city" [matAutocomplete]="auto1" />
              <img matPrefix *ngIf="form.get('from_city').value && form.get('from_city').value['flag'] && form.get('from_city').valid " alt="country" class="flag" src="{{form.get('from_city').value['flag']}}" [appPhotoMissing]="'no-image.png'"/>
          <button *ngIf="form.get('from_city').value" matSuffix mat-icon-button aria-label="Clear"
            (click)="form.get('from_city').setValue('')">
            <mat-icon class="icon-color">close</mat-icon>
          </button>
        <mat-error *ngIf="form.get('from_city').invalid">{{
          getErrorMessage("from_city")
          }}</mat-error>
      </mat-form-field>
      <mat-autocomplete #auto1="matAutocomplete" class="w-100" [displayWith]="displayFn" >
        <mat-option *ngIf="isLoading$ | async" class="is-loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-option>  
        <ng-container *ngIf="!(isLoading$ | async)">
          <mat-option *ngFor="let option of fromCityOptions" [value]="option">
            <ion-thumbnail slot="start">
              <img alt="country" src="{{option['flag']}}" class="flag" [appPhotoMissing]="'no-image.png'"/>
              {{option['display_name']}}
            </ion-thumbnail>
          </mat-option>
        </ng-container>

      </mat-autocomplete>

    </div>

    <div class="w-100">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">{{
          metaDataTranslation["to_city"]["label"] | capitalize
          }}</mat-label>
        <input matInput placeholder="{{ metaDataTranslation['to_city']['placeholder'] }}" type="text"
          formControlName="to_city" [matAutocomplete]="auto2"/>
          <img matPrefix *ngIf="form.get('to_city').value && form.get('to_city').value['flag'] && form.get('to_city').valid" alt="country" class="flag"
            src="{{form.get('to_city').value['flag']}}" [appPhotoMissing]="'no-image.png'"/>
            <button *ngIf="form.get('to_city').value" matSuffix mat-icon-button aria-label="Clear"
              (click)="form.get('to_city').setValue('')">
              <mat-icon class="icon-color">close</mat-icon>
            </button>
        <mat-error *ngIf="form.get('to_city').invalid">{{
          getErrorMessage("to_city")
          }}</mat-error>
      </mat-form-field>
            <mat-autocomplete #auto2="matAutocomplete" class="w-100" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading$ | async" class="is-loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
              </mat-option>
             
              <ng-container *ngIf="!(isLoading$ | async)">
                <mat-option *ngFor="let option of toCityOptions" [value]="option">
                  <ion-thumbnail slot="start">
                    <img alt="country" src="{{option['flag']}}" class="flag" [appPhotoMissing]="'no-image.png'"/>
                    {{option['display_name']}} 
                  </ion-thumbnail>
                </mat-option>
              </ng-container>
            
            </mat-autocomplete>

    </div>
    <div *ngIf="fromParentComponent && fromParentComponent != 'AdsPage'">
      <mat-form-field appearance="fill" class="w-100" >
        <mat-label>{{
          metaDataTranslation["travel_date"]["label"] | capitalize
          }}</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="travel_date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
        <mat-error *ngIf="form.get('travel_date').invalid">{{
          getErrorMessage("travel_date")
          }}</mat-error>
      </mat-form-field>

    </div>
  </div>
</form>