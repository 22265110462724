import { Injectable } from '@angular/core';
import { AlertController, LoadingController, PopoverController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { PopoverComponent } from 'src/app/components/shared/popover/popover.component';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private loading = new BehaviorSubject<boolean>(false);
  getloading$ = this.loading.asObservable();
  setloading(x: boolean) {
    this.loading.next(x);
  }
  currentloading(): Boolean {
    return this.loading.value;
  }
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private popoverController: PopoverController,
    private localStorageService: LocalStorageService,
    private router:Router
  ) {}
  async presentToast(position: 'top' | 'middle' | 'bottom', message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 10000,
      position: position,
      color: color,
      buttons: [
        {
          text: 'X',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }
  async presentToastCookie(
    position: 'top' | 'middle' | 'bottom',
    message,
    color
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: 50000,
      position: position,
      color: color,
      buttons: [
        {
          text: '',
          icon: 'alert-circle-outline',
          role: 'dismiss',
          handler: () => {
            this.router.navigate(['/extras/cookies']);
          },
        },
        {
          text: 'X',
          role: 'cancel',
          handler: () => {
            this.localStorageService.set('cookie', 1);
          },
        },
      ],
    });

    await toast.present();
  }
  async presentAlert(msg, cancelText, confirmText) {
    const alert = await this.alertController.create({
      header: msg,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
        },
        {
          text: confirmText,
          role: 'confirm',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
  async showLoadingTrue(msg) {
    // c'est pour le spnner lors du scraping
    this.setloading(true);
    const loading = await this.loadingCtrl.create({
      message: msg,
      duration: 60000,
    });
    loading.present();
  }
  async showLoadingFalse() {
    if (this.currentloading()) {
      await this.loadingCtrl.dismiss();
      this.setloading(false);
    }
  }
  async presentPopover(msg) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      componentProps: { msg: msg },
    });

    await popover.present();

    const { role } = await popover.onDidDismiss();
  }
}
