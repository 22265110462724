import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-ok',
  templateUrl: './send-ok.component.html',
  styleUrls: ['./send-ok.component.scss'],
})
export class SendOkComponent implements OnInit {
  data: any;
  type = 'ad';
  dataSubscription: Subscription;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
  
  }
  ionViewWillEnter() {
  this.dataSubscription = this.route.queryParams.subscribe((p) => {
    if (p && p['type']) {
      this.type = p['type'];
    }else{
      this.type = '';
    }
  });
  }
  home() {
    this.router.navigate(['/']);
  }
  shopping() {
    this.router.navigate(['/shopping']);
  }
  accountButton() {
    this.router.navigate(['account']);
  }
    ionViewWillLeave() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }}
}
