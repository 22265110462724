export const firebase = {
  //on va l'exclure de gits
  firebaseConfig: {
    apiKey: 'AIzaSyAmHTEht_HICOEZ9B_fkf_A1w9aXzzawOg',
    authDomain: 'ilivou.com',
    // authDomain: 'ilivou-23263.firebaseapp.com',
    projectId: 'ilivou-23263',
    storageBucket: 'ilivou-23263.appspot.com',
    messagingSenderId: '439863660250',
    appId: '1:439863660250:web:15d46ff3908de72d2f5fe3',
    measurementId: 'G-5C5ZM2BLPZ',
  },
};
