<ion-grid fixed class="my-container-500 ">

  <div class="ion-text-right" *ngIf="!fromParentIsModal">
       <div fxLayout="row" fxLayoutAlign="space-between start" [ngStyle]="{'margin-top': '10px'}">
            <ion-icon slot="primary" (click)="openWhatsApp()" name="logo-whatsapp" size="large" class="whatsapp-color"
              slot="end"></ion-icon>
  
      <ion-chip [outline]="true" (click)="login()" *ngIf="!isLogin">{{ "signin" | translate}}
      </ion-chip>
       </div>
    <mat-divider></mat-divider>

  </div>

  <div class="ion-text-center">
    <h3 [ngStyle]="{'margin': '5px'}">{{ "registration" | translate}}</h3>
  </div>
  <app-social-auth [dataFromParent]="dataFromParent"></app-social-auth>
  <div fxLayout="row" fxLayoutAlign="center start" [ngStyle]="{'margin': '5px'}">
    {{"or" | translate }}
  </div>
  <form #f="ngForm" [formGroup]="form" (ngSubmit)="send()">
    <div class="w-100 ">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">
          {{"signup.form.email.label" | translate | capitalize}}
        </mat-label>
        <input matInput placeholder='{{"signup.form.email.placeholder" | translate | capitalize}}' type="email"
          formControlName="email" />

        <mat-error *ngIf="form.get('email').invalid">{{
          getErrorMessage("email")
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-100 ">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">
          {{"signup.form.first_name.label" | translate | capitalize}}
        </mat-label>
        <input matInput placeholder='{{"signup.form.first_name.placeholder" | translate | capitalize}}' type="text"
          formControlName="first_name" />

        <mat-error *ngIf="form.get('first_name').invalid">{{
          getErrorMessage("first_name")
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-100 ">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">
          {{"signup.form.last_name.label" | translate | capitalize}}
        </mat-label>
        <input matInput placeholder='{{"signup.form.last_name.placeholder" | translate | capitalize}}' type="text"
          formControlName="last_name" />

        <mat-error *ngIf="form.get('last_name').invalid">{{
          getErrorMessage("last_name")
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="phone">
      <div [ngStyle]="{'flex-grow': '1'}">
        <mat-form-field appearance="outline" [ngStyle]="{'width': '100%'}">
          <mat-label>{{
            "account.userProfil.form.phone_number.label"
            | translate
            | capitalize
            }}</mat-label>
          <input type="tel" matInput placeholder="Ex: 0610789845" formControlName="phone_number">
          <mat-hint>{{
            "kycLightNotVisiblePhone"
            | translate
            | capitalize
            }}</mat-hint>
            <mat-error *ngIf="form.get('phone_number').invalid">{{
              getErrorMessage("phone_number")
              }}</mat-error>

        </mat-form-field>

      </div>
    </div>
    <div [ngStyle]="{'height': '25px'}">

    </div>
    <div formGroupName="passwordForm">
      <div class="w-100 ">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{"signup.form.password.label" | translate | capitalize}}
          </mat-label>
          <input id="mysecretpassword" matInput
            placeholder='{{"signup.form.password.placeholder" | translate | capitalize}}' required
            formControlName="password" type="password" showHideInput />
          <i matSuffix class="material-icons" showHideTrigger="mysecretpassword"
            [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"></i>
          <mat-error *ngIf="form.get('passwordForm.password').invalid">{{
            getErrorMessage("passwordForm.password")
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100 ">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{"signup.form.confirmpassword.label" | translate | capitalize}}
          </mat-label>
          <input id="mysecretpassword" matInput
            placeholder='{{"signup.form.confirmpassword.placeholder" | translate | capitalize}}' required
            formControlName="confirmpassword" type="password" showHideInput />
          <i matSuffix class="material-icons" showHideTrigger="mysecretpassword"
            [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"></i>
          <mat-error *ngIf="form.get('passwordForm.confirmpassword').invalid">{{
            getErrorMessage("passwordForm.confirmpassword")
            }}</mat-error>
        </mat-form-field>
        <ion-label color="danger"
          *ngIf="passwordForm.get('confirmpassword').touched && passwordForm.hasError('matchingError')">
          {{"errorForm.matchingError" | translate | capitalize}}

        </ion-label>

      </div>

    </div>

    <ion-button type="submit" [disabled]="form.invalid" class="w-100">
      {{"signup.button" | translate | capitalize}}
    </ion-button>

  </form>
 
  <div class="ion-text-end" *ngIf="!fromParentIsModal" [ngStyle]="{'margin-top': '15px'}" ffxLayout="row"
    fxLayoutAlign="center center">
    <ion-note>{{"tohave_account" | translate | capitalize}}
    </ion-note>
    <ion-button (click)="login()" *ngIf="!isLogin" fill="clear" size="small">
      {{ "signin" | translate}}
    </ion-button>

  </div>
  <mat-divider>
  </mat-divider>
</ion-grid>
<ion-grid fixed class="my-container-500 ">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="terms">
    <div>
      <ion-note [ngStyle]="{'font-size': '10px'}" color="primary">
        <a [routerLink]="['/extras/terms-of-use']" target="_blank">{{"termsOfService" | translate | uppercase}}</a>
      </ion-note>

    </div>
    <div>
      <ion-note color="primary" [ngStyle]="{'font-size': '10px'}">
        <a [routerLink]="['/extras/privacy-policy']" target="_blank"> {{"privacyAndSecurity" | translate |
          uppercase}}</a>
      </ion-note>
    </div>
  </div>
</ion-grid>