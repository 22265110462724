import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-confirm-delivery',
  templateUrl: './confirm-delivery.component.html',
  styleUrls: ['./confirm-delivery.component.scss'],
})
export class ConfirmDeliveryComponent implements OnInit {
  modalData;
  data;
  fromComponent;
  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.data = this.modalData['data'];
    this.fromComponent = this.modalData['fromComponent'];
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  confirm() {
    return this.modalService.confirm({});
  }
  confirmCode(){
     return this.modalService.confirm({
      codeGiven:true
     });
  }
  sendCodeReceived(form: NgForm) {
    const code = form.value['received'];
    const delivery_uuid = this.data['delivery_uuid'];
    if (code) {
      return this.modalService.confirm({
        code: code,
        delivery_uuid: delivery_uuid,
        status: 'DEL',
      });
    }
  }
}
