import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';

@Component({
  selector: 'app-token-connexion',
  templateUrl: './token-connexion.component.html',
  styleUrls: ['./token-connexion.component.scss'],
})
export class TokenConnexionComponent implements OnInit {
  token;
  ws;
  constructor(
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router,
    private apiService:ApiService
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    this.token = params['tok'];
    this.localStorageService.setToken('token', {'access':this.token});  
    setTimeout(() => {
      this.apiService.getCurrentUserWithoutToken(this.token).subscribe((x) => {
        console.log(x);
      });
    }, 2000);
  }
}
