import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
})
export class OfferDetailsComponent implements OnInit {
  modalData; //cela vient du modal voir le modalService
  currency = environment.currency;
  checkValue = true;
  summaryData;
  fromComponent;
  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.summaryData = this.modalData['data'];
    this.fromComponent = this.modalData['fromComponent'];
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  refuse(){
    //refuser l'offre
      return this.modalService.decline(this.summaryData);

  }
  confirm() {
    const cancelText = this.translateService.instant('cancel');
    const confirmText = this.translateService.instant('yes');
    const msg = this.translateService.instant('OfferDetailsAccept');
    this.toastService
      .presentAlert(msg, cancelText, confirmText)
      .then((resp) => {
        if (resp == 'confirm') {
          return this.modalService.confirm(this.summaryData);
        }
      });
  }
}
