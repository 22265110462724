<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"stripe_connect.title" | translate}}</ion-label>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>

  <ion-grid fixed class="my-container-500 ">

    <div class="stripe">

      <ion-label>
       {{"stripe_onboarding_msg" | translate}}
      </ion-label>
    </div>

    
    
    <ion-button fill="outline" expand="block" (click)="confirm()">{{"okay" | translate | capitalize}}</ion-button>
    
    <a href="https://stripe.com/fr/connect" target="_blank"><ion-chip color="primary">{{"stripe_more" | translate}}</ion-chip></a>
  </ion-grid>
</ion-content>

