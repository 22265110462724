import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Platform, ToastController } from '@ionic/angular';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PhotoService } from 'src/app/services/photo-service/photo.service';

class ImageSnippet {
  status = 'init';

  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnChanges {
  fileForm: FormGroup;
  selectedFile: ImageSnippet;
  imgResultBeforeCompression: string = '';
  imgResultAfterCompression: string = '';
  images: any[] = [];
  @Output() uploadedFilesEvent = new EventEmitter<any>();
  @Input() label: string = '';
  @Input() imagesFromParent = [];
  isMobile=false;

  constructor(
    private fb: FormBuilder,
    private toastController: ToastController,
    private imageCompress: NgxImageCompressService,
    private photoService: PhotoService,
    private platform: Platform
  ) {}

  ngOnInit() {
    
    this.isMobile = this.platform.is('mobile');
    this.fileForm = this.fb.group({
      image: [''],
    });
  }
  uploadFile() {
    if (!this.isMobile) {
      //si web
      this.uploadFileWeb();
    }else{
      //si mobile
      this.uploadFileAppNative();

    }
  }
  compressFile(image, orientation) {
    //voir comment rendre l'image plus nette
    this.imageCompress
      .compressFile(image, orientation, 70, 70) // 50% ratio, 50% quality
      .then((compressedImage) => {
        this.imgResultAfterCompression = compressedImage;
        this.images.push({
          image: compressedImage,
          
        });
        this.uploadedFilesEvent.emit(this.images);
      });
  }
  uploadFileWeb() {
    //pour le web
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imgResultBeforeCompression = image;

      this.compressFile(image, orientation);
    });
  }
  uploadFileAppNative() {
    //Pour les applications natives
    this.photoService
      .addNewToGallery()
      .then((image) => {
        this.compressFile(image, -1);
      })
      .catch((e) => {
      });
  }

  deleteFile(indexx) {
    this.images = this.images.filter((i, index) => index !== indexx);
    this.uploadedFilesEvent.emit(this.images);
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Que jpeg ou png',
      duration: 10000,
      position: 'top',
      buttons: [
        {
          text: 'X',
          role: 'cancel',
        },
      ],
      color: 'danger',
    });

    await toast.present();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['imagesFromParent']) {
      this.images = changes['imagesFromParent'].currentValue;      
      this.uploadedFilesEvent.emit(this.images);
    }

    //Detection de la mise à jour des données par le composant parent
  }
}
