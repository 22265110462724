import { firebase } from './firebase';

export const common = {
  ...firebase,
  apiPhotonReverseGeocoder: 'https://photon.komoot.io/reverse',
  currency: 'EUR',
  nominatimUrl: 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&',
  maxPrice: 10000, //prix maximum
  minPrice: 1, //prix min
  minReward: 5, //prix min
  redirect_uri_facebook:
    'https://localhost:8000/api/auth/social-redirection/facebook/',
  is_beta_test: false,
  ph_project_api_key: 'phc_GZrRmNMcikQ14X87ALcxa6cxiyhLwtsX9el46ysfuE4',

  ngxUiLoaderExcludeUrl: ['api/cities/list/', 'api/gniparcs/search-item/'], //exclut du spinner loader
  preferredCountries: [
    'fr',
    're',
    'bl',
    'mf',
    'nc',
    'gp',
    // 'gf',
    'mq',
    'yt',
    'pf',
  ],
  formatDate: 'YYYY/MM/DD',
  pageSize: 10, // car ce nombre doit correspondre à celui du back "PAGE_SIZE': 10"
  IsCallToAction: false,
};
