import { APP_CONSTANTS } from './../../constants/app.constants';
import {
  HttpClient,
  HttpContext,
  HttpContextToken,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';
import {
  HAS_NEED_TOKEN,
  IS_FROM_GUARDS,
} from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment';
import { DatawarehouseService } from '../datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { UtilsService } from '../utils-service/utils.service';
import { WebsocketService } from '../websocket-service/websocket.service';
import { SocialAuthService } from '../social-auth-service/social-auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  apiPhotonReverseGeocoder = environment.apiPhotonReverseGeocoder;
  redirect_uri_facebook = environment.redirect_uri_facebook;
  constant = APP_CONSTANTS;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private datawarehouseService: DatawarehouseService,
    private utilsService: UtilsService,
    private websocketService: WebsocketService,
    private socialAuthService: SocialAuthService,
    private translateService: TranslateService,

    private router: Router
  ) {}
  //****************public*************************** */

  scraping(url): Observable<any> {
    //renvoit les données issu du scraping sinon une erreur 400
    let formData = new FormData();
    formData.append('url', url);
    return this.http.post(`${this.apiUrl}api/gniparcs/search-item/`, formData);
  }
  estimate(product_price, quantity): Observable<any> {
    //renvoit les données issu du scraping sinon une erreur 400
    let formData = new FormData();
    formData.append('product_price', product_price);
    formData.append('quantity', quantity);
    return this.http.post(`${this.apiUrl}api/shopping/estimate/`, formData);
  }
  getCityName(name): Observable<any> {
    //recherche le nom d'un lieu(utilisé dans travelFOrm)
    const name_without_space = name
      .normalize('NFD') //accent
      .replace(/[\u0300-\u036f]/g, '') // accent
      .replace(/[^\w ]/g, '') //suppression caratères spéciaux
      .replace(/[^a-zA-Z ]/g, '') //suppression des chiffres;
      .replace(/\s/g, ''); //espace
    const name_to_lower = name_without_space.toLowerCase();
    const display_name = 'search_names';
    let params = new HttpParams().set(display_name, name_to_lower); //il faut que le fr soit
    return this.http
      .get(`${this.apiUrl}api/cities/list/`, { params: params })
      .pipe(map((t) => t['results'])); //c'est normal ne pas enlever
  }
  getAllWorldCountries(): Observable<any> {
    //obtenir tous les pays du monde
    return this.http.get(`${this.apiUrl}api/all-world-countries/`);
  }
  getTravels(from_city, to_city): Observable<any> {
    //recherche les voyages
    let params = new HttpParams()
      .set('from_city', from_city)
      .set('to_city', to_city);
    return this.http
      .get(`${this.apiUrl}api/travel/list-create/`, {
        params: params,
      })
      .pipe(map((t) => t['results'])); //c'est normal ne pas enlever
  }
  getShopping(paramsObj): Observable<any> {
    let params = new HttpParams();
    for (const key in paramsObj) {
      if (Object.prototype.hasOwnProperty.call(paramsObj, key)) {
        const element = paramsObj[key];
        params = params.set(key, element);
      }
    }
    return this.http.get(`${this.apiUrl}api/shopping/list-create/`, {
      params: params,
    });
  }

  getLast10Shopping(): Observable<any> {
    return this.http.get(`${this.apiUrl}api/shopping/list-create`);
    // .pipe(map((t) => t['results']));
  }
  getLast10Products(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}api/product/list/?limit=10`)
      .pipe(map((t) => t['results'])); //pas utilisé
  }
  getExtras(title): Observable<any> {
    return this.http
      .get(`${this.apiUrl}api/extras/list/` + title + '/')
      .pipe(map((t) => t['results'])); ////c'est normal ne pas enlever mais le back ne doit jamais depasser 10
  }
  getSingleShoppingDetail(uuid): Observable<any> {
    return this.http.get(`${this.apiUrl}api/shopping/detail/` + uuid + '/');
  }
  getShoppingUser(uuid): Observable<any> {
    // récupére le shopping des users sans connexion
    //utilisé pour afficher les shoppings dans user-safe-details
    return this.http
      .get(`${this.apiUrl}api/shopping/users/` + uuid + '/')
      .pipe(map((t) => t['results'])); // c'est nomal , on récupère que les 10  derniers shopp du user
  }
  getTravelUser(uuid): Observable<any> {
    // récupére le travel des users sans connexion
    //utilisé pour afficher les travels dans user-safe-details
    return this.http
      .get(`${this.apiUrl}api/travel/users/` + uuid + '/')
      .pipe(map((t) => t['results'])); // c'est nomal , on récupère que les 10  derniers travel du user
  }
  getUserSafeDetail(uuid): Observable<any> {
    return this.http.get(`${this.apiUrl}api/users/safe-data/` + uuid + '/');
  }
  getPagination(url): Observable<any> {
    //pour récupérer next et previous des paginations
    let current_url = url; //que ça soit next ou previous
    const env = environment.production;
    if (env == true) {
      current_url = url.replace('http://', 'https://');
    }
    return this.http.get(current_url);
  }
  getPaginationWithToken(url): Observable<any> {
    //pour récupérer next et previous des paginations
    // voir getPagination
    let current_url = url; //que ça soit next ou previous
    const env = environment.production;
    if (env == true) {
      // faire cela dans le back
      current_url = url.replace('http://', 'https://');
    }
    return this.http.get(current_url, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }

  getAvailablesCountries(): Observable<any> {
    //les pays disponibles
    return this.http.get(`${this.apiUrl}api/countries/list/`);
  }
  //********************************inscription et connexion**************************************** */
  signUp(userLoginData): Observable<any> {
    let formData = new FormData();
    formData.append('email', userLoginData['email']);
    formData.append('password', userLoginData['password']);
    formData.append('first_name', userLoginData['first_name']);
    formData.append('last_name', userLoginData['last_name']);
    formData.append('phone_number', userLoginData['phone_number']);
    return this.http.post(`${this.apiUrl}api/users/`, formData, {
      context: new HttpContext().set(HAS_NEED_TOKEN, false),
    });
  }
  login(userLoginData): Observable<any> {
    let formData = new FormData();
    formData.append('email', userLoginData['email']);
    formData.append('password', userLoginData['password']);
    return this.http.post(`${this.apiUrl}api/users/login`, formData);
  }
  activationUserByMail(uid, token): Observable<any> {
    //activer le client lorsqu'il vient de s'incrire en lui envoyant un mail
    let formData = new FormData();
    formData.append('uid', uid);
    formData.append('token', token);
    return this.http.post(`${this.apiUrl}api/users/activation/`, formData);
  }
  reportAbus(shopping, description): Observable<any> {
    //activer le client lorsqu'il vient de s'incrire en lui envoyant un mail
    let formData = new FormData();
    formData.append('shopping', shopping);
    formData.append('description', description);
    return this.http.post(`${this.apiUrl}api/shopping/report-abus/`, formData, {
      context: new HttpContext()
        .set(HAS_NEED_TOKEN, true)
        .set(IS_FROM_GUARDS, true),
    });
  }
  confirmResetPassword(uid, token, new_password): Observable<any> {
    //activer le client lorsqu'il vient de s'incrire en lui envoyant un mail
    let formData = new FormData();
    formData.append('uid', uid);
    formData.append('token', token);
    formData.append('new_password', new_password);
    return this.http.post(
      `${this.apiUrl}api/users/reset_password_confirm/`,
      formData
    );
  }
  resetPassword(email): Observable<any> {
    //reset le password
    let formData = new FormData();
    formData.append('email', email);
    return this.http.post(`${this.apiUrl}api/users/reset_password/`, formData);
  }

  firebaseGoogleUserExist(id_token): Observable<any> {
    //on vérifie si l'utilisateur existe dans la base avant
    let formData = new FormData();
    formData.append('id_token', id_token);
    return this.http.post(
      `${this.apiUrl}api/users/firebase/auth/user`,
      formData
    );
  }
  firebaseGoogleSignUp(id_token, phone_number): Observable<any> {
    let formData = new FormData();
    formData.append('id_token', id_token);
    formData.append('phone_number', phone_number);
    return this.http.post(
      `${this.apiUrl}api/users/firebase/auth/signup`,
      formData
    );
  }
  firebaseGoogleLogin(id_token): Observable<any> {
    let formData = new FormData();
    formData.append('id_token', id_token);
    return this.http.post(
      `${this.apiUrl}api/users/firebase/auth/login`,
      formData
    );
  }
  logoutCommon() {
    this.datawarehouseService.setloginData(null);
    this.websocketService.close();
    try {
      this.socialAuthService.signOut();
    } catch (error) {}
    const cookie = this.localStorageService.get('cookie');
    return this.localStorageService.clear().then((r) => {
      //il faut attendre que ça finisse
      this.datawarehouseService.setloginData(null);
    });
  }
  logoutRedirectHome() {
    this.logoutCommon().then((resp) => {
      this.router.navigate(['']);
    });
  }
  logoutRedirectAccount() {
    this.logoutCommon().then((resp) => {
      this.router.navigate(['/login']);
    });
  }

  refreshToken(refresh_token): Observable<any> {
    let formData = new FormData();
    formData.append('refresh', refresh_token);
    return this.http.post(`${this.apiUrl}api/users/token/refresh/`, formData);
  }

  //*********************************user(il faut etre connecté pour utiliser les fonctions ci dessous)****************************************** */
  getCurrentUserWithToken(is_from_guards: boolean = false): Observable<any> {
    //utilisé cette requete pour récupérer les données du clients(même si uuid, on ne peut modifier)
    //ne pas utiliser getUserProfile car il y a pas de uuid
    return this.http
      .get(`${this.apiUrl}api/users/me/`, {
        context: new HttpContext()
          .set(HAS_NEED_TOKEN, true)
          .set(IS_FROM_GUARDS, is_from_guards),
      })
      .pipe(
        map((resp) => {
          if (resp && resp['user']) {
            this.datawarehouseService.setloginData(resp);
            return true;
          }
          return false;
        })
      );
  }
  getCurrentUserWithoutToken(auth_token): Observable<any> {
    //utilisé cette requete pour récupérer les données du clients(même si uuid, on ne peut modifier)
    //ne pas utiliser getUserProfile car il y a pas de uuid
    return this.http
      .get(`${this.apiUrl}api/users/me/`, {
        headers: new HttpHeaders().append(
          'Authorization',
          `Bearer ${auth_token}`
        ),
      })
      .pipe(
        map((resp) => {
          if (resp && resp['user']) {
            this.datawarehouseService.setloginData(resp);
            return true;
          }
          return false;
        })
      );
  }
  getUserProfile(user_uuid): Observable<any> {
    // récupére les données du user

    return this.http.get(
      `${this.apiUrl}api/users/retrieve-update-data/` + user_uuid + '/',
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  patchUserProfile(user_uuid, paramsObj): Observable<any> {
    // mise à jour des données du user
    let formData = new FormData();
    for (const key in paramsObj) {
      if (Object.prototype.hasOwnProperty.call(paramsObj, key)) {
        const value = paramsObj[key];
        formData.append(key, value);
      }
    }

    return this.http.patch(
      `${this.apiUrl}api/users/retrieve-update-data/` + user_uuid + '/',
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  postShopping(paramsObj): Observable<any> {
    return this.http.post(
      `${this.apiUrl}api/shopping/list-create/`,
      paramsObj,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  postTravels(paramsObj): Observable<any> {
    return this.http.post(`${this.apiUrl}api/travel/list-create/`, paramsObj, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  postOffer(paramsObj): Observable<any> {
    return this.http.post(`${this.apiUrl}api/offer/list-create/`, paramsObj, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  acceptOffer(offer_uuid): Observable<any> {
    //le shopper accept l'offre
    let formData = new FormData();
    formData.append('offer_uuid', offer_uuid);
    formData.append('payment_method', 'STR');
    formData.append('type_payment', this.constant.typePayments.WEB.value);
    return this.http.post(`${this.apiUrl}api/payment/do-payment/`, formData, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  getBalance(): Observable<any> {
    //récupérer le solde du user
    return this.http.post(
      `${this.apiUrl}api/payment/balance-user/`,
      {},
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }

  confirmDeliveryCode(code, delivery_uuid, status): Observable<any> {
    //code de confirmation
    let formData = new FormData();
    formData.append('code', code);
    formData.append('status', status);
    return this.http.put(
      `${this.apiUrl}api/delivery/detail/` + delivery_uuid + '/',
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  cancelDelivery(delivery_uuid): Observable<any> {
    //traveler qui annule la livraison
    let formData = new FormData();
    formData.append('status', 'CAN');
    return this.http.put(
      `${this.apiUrl}api/delivery/detail/` + delivery_uuid + '/',
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  travelerCancelOffer(offer_uuid): Observable<any> {
    //le traveler annule l'offre  avant même que le shopper puisse refuser ou valider
    let formData = new FormData();
    formData.append('status', 'CAN');
    return this.http.put(
      `${this.apiUrl}api/offer/detail/` + offer_uuid + '/',
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  shopperDeclineOffer(offer_uuid): Observable<any> {
    //le shopper refuse l'offre
    let formData = new FormData();
    formData.append('status', 'NOA'); //NO Accepted
    return this.http.put(
      `${this.apiUrl}api/offer/detail/` + offer_uuid + '/',
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  getOfferMessageDetails(offer_uuid): Observable<any> {
    //le shopper refuse l'offre
    return this.http.get(
      `${this.apiUrl}api/offer/message-detail/` + offer_uuid + '/',
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }

  deleteShoppings(status): Observable<any> {
    // supprimer un shopping
    return this.http.delete(
      `${this.apiUrl}api/shopping/detail/` + status + '/',
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  deleteTravel(uuid): Observable<any> {
    // supprimer un shopping
    return this.http.delete(`${this.apiUrl}api/travel/detail/` + uuid + '/', {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }

  getMyShoppings(status): Observable<any> {
    // récupére les shopping du user
    return this.http.get(`${this.apiUrl}api/my-shoppings/` + status + '/', {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
    // .pipe(map((t) => t['results']));
  }
  getMyOrders(status): Observable<any> {
    // récupére les shopping du user
    return this.http.get(`${this.apiUrl}api/orders/list/` + status + '/', {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
    // .pipe(map((t) => t['results']));
  }
  getMyDeliveries(status): Observable<any> {
    // récupére les shopping du user
    return this.http.get(`${this.apiUrl}api/delivery/list/` + status + '/', {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
    // .pipe(map((t) => t['results']));
  }
  getMymessages(): Observable<any> {
    // récupére les messages liées aux offres du user
    return this.http.get(`${this.apiUrl}api/offer/messages-list/`, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
    // .pipe(map((t) => t['results']));
  }

  updateMessages(offer_uuid): Observable<any> {
    // mets à jour les messages liées aux offres du user avec read_or_not=True si il est le destinataire du message
    let formData = new FormData();
    formData.append('offer_uuid', offer_uuid); //NO Accepted
    return this.http.post(`${this.apiUrl}api/messages/update/`, formData, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  postReview(delivery_uuid, rating, comments): Observable<any> {
    // poster un avis
    let formData = new FormData();
    formData.append('delivery_uuid', delivery_uuid);
    formData.append('rating', rating);
    formData.append('comments', comments);
    return this.http.post(`${this.apiUrl}api/review/list-create/`, formData, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  getMyTravels(status): Observable<any> {
    // récupére les travels du user
    return this.http.get(`${this.apiUrl}api/my-travels/` + status + '/', {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
    // .pipe(map((t) => t['results']));
  }
  getMyTravelsWithoutPagination(status): Observable<any> {
    // récupére les travels du user dans make offer
    return this.http
      .get(`${this.apiUrl}api/my-travels/` + status + '/?limit=100', {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      })
      .pipe(map((t) => t['results'])); // c'est normal on récupère que les derniers trajets mais il faudra le prendre en compte
  }
  getTravelersOfferByTravel(id, status): Observable<any> {
    // récupére les offres du voyageur liés au status et à un voyage
    return this.http
      .get(`${this.apiUrl}api/offers-travel/` + id + '/' + status + '/', {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      })
      .pipe(map((t) => t['results'])); // le composant n'est pas utilisé
  }
  getShopperOfferByShopping(id, status): Observable<any> {
    /// récupére les offres du shopper liés au status et à un shopping
    return this.http
      .get(`${this.apiUrl}api/offers-shopping/` + id + '/' + status + '/', {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      })
      .pipe(map((t) => t['results'])); // le composant n'est pas utilisé
  }
  getTravelerPayouts(): Observable<any> {
    /// récupére les paiements du traveler
    return this.http
      .get(`${this.apiUrl}api/delivery/traveler-payouts/`, {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      })
      .pipe(map((t) => t['results'])); //le composant ne les utilise pas
  }
  getMyBalance(): Observable<any> {
    /// récupére les paiements
    return this.http.get(`${this.apiUrl}api/delivery/my-balance/`, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  getShopperPayouts(): Observable<any> {
    /// #list des paiements effectués  par le shopper
    return this.http
      .get(`${this.apiUrl}api/payment/shopper-payout/`, {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      })
      .pipe(map((t) => t['results'])); // le composant le les utilise pas
  }

  postStripeAccountLink(return_url): Observable<any> {
    // avoir un link onboardin stripe
    let formData = new FormData();
    formData.append('return_url', return_url);
    return this.http.post(
      `${this.apiUrl}api/users/stripe_connect/accountLink/`,
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }

  getMyAlerts(): Observable<any> {
    // récupére les offres du user
    return this.http.get(`${this.apiUrl}api/my-alerts-travelss/list-create/`, {
      context: new HttpContext().set(HAS_NEED_TOKEN, true),
    });
  }
  updateMyAlerts(id, actif, to_delete): Observable<any> {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('actif', actif);
    formData.append('to_delete', to_delete);
    return this.http.post(
      `${this.apiUrl}api/my-alerts-travelss/update/`,
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
  postsMyAlerts(to_city, from_city, travel_date): Observable<any> {
    let formData = new FormData();
    formData.append('to_city', to_city);
    formData.append('from_city', from_city);
    formData.append('travel_date', travel_date);
    return this.http.post(
      `${this.apiUrl}api/my-alerts-travelss/list-create/`,
      formData,
      {
        context: new HttpContext().set(HAS_NEED_TOKEN, true),
      }
    );
  }
}
