import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-deliveries-orders-last',
  templateUrl: './deliveries-orders-last.component.html',
  styleUrls: ['./deliveries-orders-last.component.scss'],
})
export class DeliveriesOrdersLastComponent implements OnInit {
  @Input() data;
  constructor() {}

  ngOnInit() {}
}
