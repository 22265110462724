import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseModule } from 'src/app/shared-module/base/base.module';
import { ErrorComponent } from './error-component/error.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, BaseModule],
  exports: [RouterModule],
})
export class ErrorRoutingModule {}
