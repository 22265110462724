import { MenuController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  name;
  dataSubscription: Subscription;
  details_submitted= false;// vérifie si le user peut recevoir un viremement

  constructor(
    private router: Router,
    private menuController: MenuController,
    private apiService: ApiService,
    private datawarehouseService: DatawarehouseService
  ) {}

  ngOnInit() {
    this.init();
  }
  ionViewWillEnter() {
    this.init();
  }
  init() {
    this.dataSubscription = this.datawarehouseService.getLoginData$.subscribe(
      (d) => {
        if (d && d['user']) {
          const user = d['user'];
          this.name = user['first_name'] + ' ' + user['last_name'];
          this.details_submitted = user['sc_user']['details_submitted'];
        }
      }
    );
  }
  going(url) {
    this.router.navigate([url]);
    this.menuController.close();
  }
  logout() {
    this.apiService.logoutRedirectHome();
    this.menuController.close();
  }
  ionViewWillLeave() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
}
