import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _storage: Storage | null = null;

  private tokenData = new BehaviorSubject<any>({});
  gettokenData$ = this.tokenData.asObservable();
  settokenData(token) {
    this.tokenData.next(token);
  }
  currenttokenData() {
    return this.tokenData.value;
  }

  private shoppingCartData = new BehaviorSubject<any>(null);
  getshoppingCartData$ = this.shoppingCartData.asObservable();
  setshoppingCartData(cart) {
    this.shoppingCartData.next(cart);
  }
  currentshoppingCartData() {
    return this.shoppingCartData.value;
  }

  private wstokenData = new BehaviorSubject<any>('');
  getWstokenData$ = this.wstokenData.asObservable();
  setwstokenData(token) {
    this.wstokenData.next(token);
  }
  currentwstokenData() {
    return this.wstokenData.value;
  }
  constructor(private storage: Storage) {
    // this.init();
  }
  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }
  setToken(key: string, value: any) {
    this.settokenData(value);
    return this._storage?.set(key, value);
  }
  set(key: string, value: any) {
        if (key == 'shopping') {
          //shopping cart
          //on est obligé de faire cela pour avertir navigation.page qu'il y a une commande dans le panier
          this.setshoppingCartData('shopping');
        }
    return this._storage?.set(key, value);
  }
  setWsToken(key: string, value: any) {
    this.setwstokenData(value);
    return this._storage?.set(key, value);
  }
  get(key: string): Observable<any> {

    return from(this._storage?.get(key));
  }
  remove(key: string) {
         if (key == 'shopping') {
           //shopping cart
           //on est obligé de faire cela pour avertir navigation.page qu'il y a une commande dans le panier
           this.setshoppingCartData(null);
         }
    return this._storage?.remove(key);
  }
  clear() {
    
   return  this._storage?.keys().then(k=>{
      k.forEach(ke=>{
        if (ke != 'cookie') {
          this.remove(ke)
        }
      })
      
    });
  }
}
