import {
  Component,
  EventEmitter,

  Input,

  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  iif
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ApiService } from 'src/app/services/api-service/api.service';
import { HandleDateService } from 'src/app/services/date-handle-service/handle-date.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { TravelFormService } from './../../../services/travel-form-service/travel-form.service';

@Component({
  selector: 'app-travel-form',
  templateUrl: './travel-form.component.html',
  styleUrls: ['./travel-form.component.scss'],
})
export class TravelFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  metaDataTranslation: any;
  // @Input() metaDataTranslationFromParent: any;
  @Output() formEvent = new EventEmitter<any>();
  @Input() dataTravelItem: any; // si ça vient de dialogTravelForm
  @Input() dataFromParentComponent: any; // si ça vient de dialogTravelForm

  minDate = this.handleDateService.minDate;
  toCityOptions$: Observable<any[]>;
  fromCityOptions$: Observable<any[]>;
  toCityOptions = [];
  fromCityOptions = [];
  current_to_city = '';
  current_from_city = '';
  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();
  currentPositionData;
  setIsLoading(x) {
    this.isLoading.next(x);
  }
  currentIsLoadingValue() {
    return this.isLoading.value;
  }
  protected readonly destroy$ = new Subject();
  travelFormServiceSusbscription: Subscription;
  travel_date = this.handleDateService.currentDatePlus1Day;
  fromParentComponent;
  initialValues = {};
  flag_url = '../../../../assets/world-countries/flags/';
  formData;
  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private apiService: ApiService,
    private travelFormService: TravelFormService,
    private handleDateService: HandleDateService
  ) {}

  ngOnInit() {
    this.travelFormServiceSusbscription =
      this.travelFormService.getfromParentData$.subscribe((fromparent) => {
        this.fromParentComponent = fromparent['fromParentComponent'];
        this.travel_date = fromparent['travel_date'];
        this.metaDataTranslation = fromparent['metaDataTranslation'];
        if (fromparent['formData']) {
          this.formData = fromparent['formData'];
        }
      });

    this.getForm();
    this.fromCity();
    this.toCity();
    this.ifDataTravelItem();
  }
  ionViewWillEnter() {}
  ionViewDidEnter() {}

  getForm() {
    this.form = this.fb.group({
      from_city: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        this.autocompleteObjectValidator(),
      ]),
      to_city: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        this.autocompleteObjectValidator(),
      ]),
      travel_date: this.fb.control(this.travel_date, [Validators.required]),
    });
    if (this.fromParentComponent == 'AdsPage') {
      //pour les annonces, on enleve le validator
      this.form.get('from_city').clearValidators();
      this.form.get('to_city').clearValidators();
      this.form.get('travel_date').removeValidators(Validators.required);
    }
    if (this.formData) {
      //si il y a des données
      this.form.setValue(this.formData);
    }
    this.form.valueChanges.subscribe((v) => {
      // quand le formulaire change d'état , on envoie un evenement au parent(par exemple pour vérifier que le formulaire est valide)
      this.formEvent.emit(this.form);
    });
  }
  ifDataTravelItem() {
    //quand un user veut créer un trajet en faisant une offre.
    //plutot que lui laisser mettre n'mporte quoi dans le lieu d'arrivée,
    //on lui pré-remplit avec possibilité de modifier si jamais
    if (this.dataFromParentComponent == 'DialogTravelFormComponent') {
      if (this.dataTravelItem && this.dataTravelItem['to_city']) {
        let to_city = this.dataTravelItem['to_city'];
        to_city['flag'] = this.flag_url + to_city['flag'];
        this.form.get('to_city').setValue(to_city);
      }
      if (this.dataTravelItem && this.dataTravelItem['from_city']) {
        let from_city = this.dataTravelItem['from_city'];
        from_city['flag'] = this.flag_url + from_city['flag'];
        setTimeout(() => {
          this.form.get('from_city').setValue(from_city);
        }, 500);
      }
    }
  }
  fromCity() {
    this.getCities('from_city', this.form)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.fromCityOptions = res.map((g) => {
          g['flag'] = this.flag_url + g['flag'];
          return g;
        });
      });
  }
  toCity() {
    this.getCities('to_city', this.form)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.toCityOptions = res.map((g) => {
          g['flag'] = this.flag_url + g['flag'];
          return g;
        });
      });
  }
  swap(){
    const  from_city =  this.form.get('from_city').value;
    const to_city =   this.form.get('to_city').value;
    this.form.get('from_city').setValue(to_city);
    this.form.get('to_city').setValue(from_city);
  }

  displayFn(item) {
    //pour afficher correctement dans le mat-autocomplete    
    return item['display_name'];
  }
  getCities(ctrl: string, form: FormGroup): Observable<any> {
    //pour faire un get sur les lieux de départ et de destination
    const aFormGroup = form as FormGroup;
    return aFormGroup.get(ctrl).valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.setIsLoading(true);
      }),

      switchMap((value) =>
        iif(() => value.length >= 2, this.getCityName(value)).pipe(
          finalize(() => {
            this.setIsLoading(false);
          })
        )
      )
    );
  }
  getCityName(name) {   
    if (name && name.length >= 2) {
      return this.apiService.getCityName(name);
    }
  }
  fromAndToCItyCOuntriesAreDifferent(group: FormGroup) {
    //pour valider que les pays du lieu de départ et d'arrivée sont differents
    const to_city = group.get('to_city').value;
    const from_city = group.get('from_city').value;
    return to_city.country === from_city.country
      ? null
      : { matchingError: true };
  }
  onSubmit() {
  }
  getErrorMessage(ctrl) {
    return this.utilsService.getErrorMessage(ctrl, this.form);
  }
  toFixedWithoutRound(num, fixed) {
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }
  autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string') {
        return { invalidAutocompleteObject: { value: control.value } };
      }
      return null; /* valid option selected */
    };
  }
  resetForm() {
    this.form.get('from_city').setValue('');
    this.form.get('to_city').setValue('');
    this.form.get('travel_date').setValue('');
  }

  ngOnDestroy() {
    if (this.travelFormServiceSusbscription) {
      this.travelFormServiceSusbscription.unsubscribe();
    }
  }
}
