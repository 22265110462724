<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-spinner name="dots"></ion-spinner>

    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-grid fixed class=" " class="ion-text-center">
    <ion-note>
      {{"shoppingForm.spinnerScrapingMessage" | translate | capitalize}}
    </ion-note>
    <div [ngStyle]="{'margin-top': '10px'}">
      <ion-note>
        {{"shoppingForm.spinnerScrapingMessage2" | translate | capitalize}}
      </ion-note>
    </div>
    <div>
      <div>{{"or" | translate |  uppercase }}
</div>
      <ion-button (click)="manualyEntry()">{{"shoppingForm.manualy_entry" | translate | capitalize}}
</ion-button>

    </div>


  </ion-grid>
</ion-content>