<ion-header mode="ios" translucent="true">
  <ion-toolbar>
          <ion-label>{{"OfferDetails" | translate | capitalize}}</ion-label>


    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-grid fixed class="my-container-500 ">

<ion-item-group>


  <ion-item-divider>
    <ion-label>{{"summaryForm.productTitle.title" | translate | capitalize}}</ion-label>
  </ion-item-divider>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.product_link" | translate | capitalize}}</ion-label>
    <ng-container *ngIf="summaryData['shopping']['product']['product_link']; else elseTemplate">
      <ion-label class="ion-text-end my-ion-label"> <a href="{{summaryData['shopping']['product']['product_link']}}"
          target="_blank" rel="noopener noreferrer">{{summaryData['shopping']['product']['product_link_domain']}}</a>
      </ion-label>
    </ng-container>
    <ng-template #elseTemplate>
      <ion-label class="ion-text-end my-ion-label">
        {{"notLink" | translate | capitalize}}
      </ion-label>
    </ng-template>


  </ion-item>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.product_title" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end my-ion-label">{{summaryData['shopping']['product']['product_title']}}</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.product_price" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end my-ion-label"> 
      {{summaryData['shopping']['estimate']['price']}} {{currency}}</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.quantity" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end my-ion-label"> {{summaryData['shopping']['quantity']}}</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.with_box" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end my-ion-label" *ngIf="summaryData['shopping']['product']['with_box']"> {{"boolean.true" |
      translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end my-ion-label" *ngIf="!summaryData['shopping']['product']['with_box']"> {{"boolean.false" |
      translate | capitalize}}</ion-label>
  </ion-item>
  <ion-item lines="none">
    <ion-label class="my-ion-label">{{"summaryForm.productTitle.image" | translate | capitalize}}</ion-label>
    <div fxLayout="row" fxLayoutAlign="center start">
      <ion-thumbnail>
        <img alt="product photo" src="{{(summaryData['shopping']['product']['product_images'][0]['image']) }}" [appPhotoMissing]="'missing-avatar.png'" />
      </ion-thumbnail>
    </div>
  </ion-item>

</ion-item-group>

<ion-item-group>


  <ion-item-divider>
    <ion-label>{{"summaryForm.delivery.title" | translate | capitalize}}</ion-label>
  </ion-item-divider>
  <div [ngStyle]="{'height': '16px'}"></div>
  <div class="pad-left-16px mar-bot-15px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <ion-icon name="location" slot="start" color="primary"></ion-icon>

      <ion-label>{{"summaryForm.delivery.from_city" | translate | capitalize}}</ion-label>
    </div>
    <div class="ion-text-end pad-left-16px "><ion-label
        class="w-sp-no">{{(summaryData['shopping']['from_city']['display_name']) }}</ion-label></div>
  </div>
  <div class="pad-left-16px mar-bot-15px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <ion-icon name="location" slot="start" color="secondary"></ion-icon>

      <ion-label>{{"summaryForm.delivery.to_city" | translate | capitalize}}</ion-label>
    </div>
    <div class="ion-text-end pad-left-16px "><ion-label
        class="w-sp-no">{{(summaryData['shopping']['to_city']['display_name']) }}</ion-label></div>
  </div>
  <div class="pad-left-16px mar-bot-15px">
    <div><ion-label class="">{{"summaryForm.delivery.up_to" | translate | capitalize}}</ion-label></div>
    <div class="ion-text-end "><ion-label>{{(summaryData['shopping']['up_to']) | date }} </ion-label></div>
  </div>

</ion-item-group>

<ion-item-group>


  <ion-item-divider>
    <ion-label>{{"offer" | translate | capitalize}}</ion-label>
  </ion-item-divider>

  <div class="item-container">
    <ion-label>{{"summaryForm.estimate.reward" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end">{{summaryData['reward']}} {{currency}}</ion-label>
  </div>

  <div class="item-container">
    <ion-label>{{"summaryForm.estimate.p2p_eco_fee" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end">{{summaryData['p2p_eco_fee']}} {{currency}}</ion-label>
  </div>
    <div class="item-container">
      <ion-label>{{"summaryForm.estimate.payment_processing_fee" | translate | capitalize}}</ion-label>
      <ion-label class="ion-text-end">{{summaryData['payment_processing_fee']}}
        {{currency}}</ion-label>
    </div>
  <div class="item-container">
    <ion-label>{{"account.userOffers.taxe" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end">{{summaryData['additional_tax']}} {{currency}}</ion-label>
  </div>
  <div class="item-container">
    <ion-label>{{"offerPriceProduct" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end">{{summaryData['shopping']['estimate']['price']}} {{currency}}</ion-label>
  </div>

  <mat-divider>
  </mat-divider>
  <div class="item-container">
    <ion-label class="reward">{{"summaryForm.estimate.total" | translate | capitalize}}</ion-label>
    <ion-label class="ion-text-end reward">{{summaryData['total']}} {{currency}}</ion-label>
  </div>
  <mat-divider>
  </mat-divider>
    <div class="item-container">
      <ion-label>{{"OfferTaxDetails" | translate | capitalize}} :</ion-label>
    </div>
    <div class="item-container">
    <div class="ion-text-start" >{{summaryData['additional_tax_comments']}}</div>
    </div>

</ion-item-group>
  </ion-grid>
</ion-content>
<ion-footer *ngIf="fromComponent != 'userOfferTraveler'">
  <ion-toolbar [ngSwitch]="fromComponent">
  <div *ngSwitchCase="'UserShoppingsComponentOrder'">
      <div fxLayout="row" fxLayoutAlign="space-between start"  [ngStyle]="{'margin': '10px'}">
              <div>{{"account.userOrders.expectedDelivery" | translate | capitalize}} </div>
              <div>{{(summaryData['shopping']['up_to']) | date }}</div>
  
      </div>
            <div  *ngIf="summaryData['product_purchased']" fxLayout="row" fxLayoutAlign="space-between start" [ngStyle]="{'margin': '10px'}">
              <div>{{"account.userOrders.purchasedProduct" | translate | capitalize}} </div>
              <div>{{"yes" | translate}}</div>
            </div>
  </div>
  <div *ngSwitchCase="'UserTravelsComponentDelivery'">
      <div fxLayout="row" fxLayoutAlign="space-between start"  [ngStyle]="{'margin': '10px'}">
              <div>{{"account.userOrders.expectedDelivery" | translate | capitalize}} </div>
              <div>{{(summaryData['shopping']['up_to']) | date }}</div>
  
      </div>
            <div  *ngIf="summaryData['product_purchased']" fxLayout="row" fxLayoutAlign="space-between start" [ngStyle]="{'margin': '10px'}">
              <div>{{"account.userOrders.purchasedProduct" | translate | capitalize}} </div>
              <div>{{"yes" | translate}}</div>
            </div>
  </div>

    <div fxLayout="row" fxLayoutAlign="space-around start" *ngSwitchCase="'UserOffersComponent'">
        <ion-button expand="block" color="danger" (click)="refuse()">{{"account.userOffers.btnDecline" | translate |
          capitalize}}
        </ion-button>
        <ion-button expand="block" fill="outline" (click)="confirm()">{{"account.userOffers.btnAccepted" | translate |
        capitalize}}
        </ion-button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" *ngSwitchCase="'ChatComponent'">
        <p>
          {{"chatOfferDetails" | translate | capitalize}}
        </p>
    </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" *ngSwitchCase="'ChatComponentOfferStatusPub'">
          <ion-button expand="block" color="danger" (click)="cancel()">{{"account.userOffers.btnDecline" | translate |
            capitalize}}
          </ion-button>
          <ion-button expand="block" fill="outline" (click)="confirm()">{{"account.userOffers.btnAccepted" | translate |
            capitalize}}
          </ion-button>
        </div>
  </ion-toolbar>
</ion-footer>