import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigInitService } from './services/config-init/config-init.service';

import { registerLocaleData } from '@angular/common';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpInterceptorService } from './services/http-interceptor-service/http-interceptor.service';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import localeFr from '@angular/common/locales/fr';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { ErrorModule } from './components/shared/errors/error.module';




registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule, //laisser cela ici et pas dans un autre module
    BrowserAnimationsModule, //laisser cela ici et pas dans un autre module
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__ilivou',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    AppRoutingModule,
    TranslateModule.forRoot({
      // <--- add this
      loader: {
        // <--- add this
        provide: TranslateLoader, // <--- add this
        useFactory: createTranslateLoader, // <--- add this
        deps: [HttpClient], // <--- add this
      }, // <--- add this
    }),
    HttpClientModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      delay: 10,
      exclude: [
        // environment.apiUrl + 'api/cities/list/',
        environment.apiUrl + 'api/gniparcs/search-item/',
        // environment.apiUrl + 'api/shopping/list-create/?limit=10',
      ],
    }),

    ErrorModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (configInitService: ConfigInitService) => () =>
        configInitService.init(),
      deps: [ConfigInitService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true, displayDefaultIndicatorType: false },
    },
    CallNumber,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
