import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';


@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  //gestinnaire gloabel des erreurs clients
  constructor(private injector: Injector) {
    Sentry.init({
      dsn: 'https://1e5eccc801a64eae1b36f8ff6b23d478@o252842.ingest.us.sentry.io/4506914437660672',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
    });
  }
  handleError(error) {
    if (environment.production == true) {
      
      Sentry.captureException(error);
    }
    let router = this.injector.get(Router);
    console.error('Error from ErrorHandlerService:', error);
    if (error instanceof HttpErrorResponse) {
      //si c'est une erreur back
      
    }else{
      //si c'est une erreur client
      if (Capacitor.getPlatform() === 'web') {
        // pour gérer l'erreur auth/popup_closed_by_user causée quand l'utilisateur ferme la popop de google sign-in avant de se logger
        const firebase_popup_closed_by_user =
          'Error: Uncaught (in promise): FirebaseError';
        if (error.toString().startsWith(firebase_popup_closed_by_user)) {
        } else {
          router.navigate(['/error']);
        }
      }else{
                  router.navigate(['/error']);

      }

      }


  
  }
}
