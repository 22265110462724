import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DeliveriesOrdersLastComponent } from 'src/app/components/shared/deliveries-orders-last/deliveries-orders-last.component';
import { PhotoMissingDirective } from 'src/app/directives/photo-missing/photo-missing.directive';
import { CapitalizePipe } from 'src/app/pipes/capitalize.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { TranslationModule } from '../translation/translation/translation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { IonicModule } from '@ionic/angular';
import { UserMenuComponent } from 'src/app/components/shared/user-menu/user-menu.component';

//De base c'était pour rendre les directives communes mais en faisant du lazy loading; ce module est enfaite le module qui contient tous les modules du composant navigationPage afin d'améliorer les performances
const BASE_MODULES = [
  NgbModule,
  TranslationModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  FlexLayoutModule,
  MaterialModule,
  IonicModule,
];
const BASE_DECLARATIONS = [
  CapitalizePipe,
  SafeHtmlPipe,
  PhotoMissingDirective,
  DeliveriesOrdersLastComponent,
  UserMenuComponent,
];

@NgModule({
  declarations: [BASE_DECLARATIONS],
  imports: [CommonModule, BASE_MODULES],
  exports: [BASE_MODULES, BASE_DECLARATIONS],
})
export class BaseDirectiveModule {}
