<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"account.userOrders.confirm" | translate | capitalize}}</ion-label>


    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-grid fixed class="my-container-500 ">

    <div [ngSwitch]="fromComponent">
      <div *ngSwitchCase="'sendingCode'">
        <ion-item>
          <ion-label class="w-sp-no">1- {{"confirmDelivery.a" | translate | capitalize}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="w-sp-no">2- {{"confirmDelivery.b" | translate | capitalize}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="w-sp-no">3- {{"confirmDelivery.c" | translate | capitalize}}</ion-label>
        </ion-item>

        <form action="" [ngStyle]="{'margin': '15px'}">
          <form (ngSubmit)="sendCodeReceived(f)" #f="ngForm" class="w-100">
            <mat-form-field appearance="outline" color="primary" class="w-100">
              <mat-label>Code</mat-label>
              <input matInput placeholder='{{"confirmDelivery.form.placeholder" | translate | capitalize}}' name="received" ngModel required minlength="8" maxlength="8"
                #received="ngModel" inputmode="numeric" digitOnly />
              <mat-icon matSuffix color="accent">vpn_key</mat-icon>
            </mat-form-field>
            <div *ngIf="received.errors?.minlength">
              <span class="invalid">{{"confirmDelivery.form.error" | translate | capitalize}}</span>
            </div>
            <div *ngIf="received.errors?.maxlength">
              <span class="invalid">{{"confirmDelivery.form.error" | translate | capitalize}}</span>
            </div>
            <div class="ion-text-end">
              <ion-button (click)="sendCodeReceived(f)" expand="block"  [disabled]="f.invalid" [ngStyle]="{'maring': '15px'}">
                {{"toSend" | translate |
                capitalize}}
              </ion-button>
            </div>
          </form>
        </form>

      </div>
      <div *ngSwitchCase="'displayingCode'">
        <ion-item>
          <ion-label class="w-sp-no">1- {{"confirmDelivery.one" | translate | capitalize}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="w-sp-no">2- {{"confirmDelivery.two" | translate | capitalize}}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="w-sp-no">3- {{"confirmDelivery.three" | translate }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label class="w-sp-no ion-text-center"> {{data['others']['code']}} </ion-label>
        </ion-item>
        <ion-button (click)="confirmCode()" expand="block" >
          {{"offerData.confirmSendingCode" | translate}}
        </ion-button>
        

      </div>
    </div>











  </ion-grid>
</ion-content>