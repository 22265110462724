import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-made',
  templateUrl: './payment-made.component.html',
  styleUrls: ['./payment-made.component.scss'],
})
export class PaymentMadeComponent implements OnInit {
  //utilisé par stripe lors du retour du paiement
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.init();
  }
  init(){
    const params = this.route.snapshot.queryParams;
    let payment_intent;
    let payment_intent_client_secret;
    let redirect_status;
    if (
      params &&
      params['payment_intent'] &&
      params['payment_intent_client_secret'] &&
      params['redirect_status']
    ) {
      payment_intent = params['payment_intent'];
      payment_intent_client_secret = params['payment_intent_client_secret'];
      redirect_status = params['redirect_status'];
      
    }
  }
}
