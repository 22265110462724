import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TravelFormService {
  //le but de ce service est de permettre au composant TravelForm de savoir depuis quel parent il est appelé
  //On peut pas utiliser les @input pour savoir depuis quel parent il est appelé à cause du routing tabs de ionic
  //c'est pas comme dans angular, ou on aurait pu utiliser le ngOnInit car il est déclenché une seule fois
    private fromParentData = new BehaviorSubject<any>({});
  getfromParentData$ = this.fromParentData.asObservable();
  setfromParentData(x) {
    this.fromParentData.next(x);
  }
  currentfromParentData() {
    this.fromParentData.value;
  }


  constructor() { }
}
