<div>
 <div fxLayout="row" fxLayoutAlign="center start">
    <button mat-stroked-button class="google" (click)="authGoogle()" type="button">
      <svg viewBox="0 0 24 24" height="1.75rem" color="#ea4334" class="css-1yy1r5t">
        <g>
          <path fill="#4F81E9"
            d="M23.8 12.3c0-.9-.1-1.7-.2-2.5H12.2v4.6h6.5c-.3 1.5-1.1 2.8-2.4 3.6v3h3.9c2.3-2 3.6-5.1 3.6-8.7zm0 0"></path>
          <path fill="#57A55C"
            d="M12.2 24c3.2 0 6-1.1 7.9-2.9l-3.9-3c-1.1.7-2.4 1.1-4.1 1.1-3.1 0-5.8-2.1-6.7-4.9h-4v3.1C3.5 21.3 7.5 24 12.2 24zm0 0">
          </path>
          <path fill="#F0BD40"
            d="M5.5 14.3c-.2-.7-.4-1.5-.4-2.3 0-.8.1-1.6.4-2.3V6.6h-4C.7 8.2.2 10.1.2 12s.5 3.8 1.3 5.4l4-3.1zm0 0"></path>
          <path fill="#D64F3E"
            d="M12.2 4.8c1.8 0 3.3.6 4.6 1.8l3.4-3.4c-2.1-1.9-4.8-3.1-8-3.1C7.5 0 3.5 2.7 1.5 6.6l4 3.1c1-2.8 3.6-4.9 6.7-4.9zm0 0">
          </path>
        </g>
      </svg>
            {{"socialAuth.google.auth" | translate }}

    </button>
 </div>
</div>
