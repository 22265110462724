import { common } from "./common";

export const environment = {
  ...common,
  production: true,
  apiUrl: 'https://api.ilivou.com/',
  ngxUiLoaderExcludeUrl: 'api/cities/list/', //exclud du spinner loader
  preferredCountries: ['fr', 're'],
  wsEndpoint: 'wss://api.ilivou.com/ws/chat/',
  mango_pay_is_actived: true,
  frontend_url: 'https://ilivou.com/',
  cookies: 'https://ilivou.com/extras/cookies',
  stripe_public_key:
    'pk_live_51NZAArDiwV8l7ZMmYayi179dGzFj3ltW71Bf1qFQOlNq573hRCgDfnyKG9XBbWx2Wy9iuP8gJVDrGmmu7cHZzc2N00wYgygiZH',
};
