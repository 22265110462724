import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { environment } from 'src/environments/environment';
import parsePhoneNumber from 'libphonenumber-js';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-kyc-light',
  templateUrl: './kyc-light.component.html',
  styleUrls: ['./kyc-light.component.scss'],
})
export class KycLightComponent implements OnInit {
  form: FormGroup;
  modalData; //cela vient du modal voir le modalService
  allWorldCountries = [];
  preferredCountries = environment.preferredCountries;
  defaultCountryiso = 'fr';

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private modalService: ModalService,
    private datawarehouseService: DatawarehouseService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.getForm();
    // this.getDatas();
  }
  getDatas() {
    this.datawarehouseService.getallWorldCountriesData$.subscribe((c) => {
      this.allWorldCountries = c;
      if (
        this.allWorldCountries &&
        this.allWorldCountries.find((f) => f['code'] == 'FR')
      ) {
        this.form
          .get('nationality')
          .setValue(this.allWorldCountries.find((f) => f['code'] == 'FR'));
      }
    });
  }
  getForm() {
    this.form = this.fb.group({
      phone_number: this.fb.control('', [
        Validators.required,
        Validators.maxLength(50),
      ]),

      // nationality: this.fb.control('', [Validators.required]),
    });
  }
  getErrorMessage(ctrl) {
    return this.utilsService.getErrorMessage(ctrl, this.form);
  }
  send() {
    const value = JSON.parse(JSON.stringify(this.form.value));

    return this.modalService.confirm(value);
  }
  cancel() {
    //pour fermer la modal
        const msg = this.translateService.instant('areYouSurToCancelSignup');
        const cancelText = this.translateService.instant('cancel');
        const confirmText = this.translateService.instant('yes');
        this.toastService
      .presentAlert(msg, cancelText, confirmText)
      .then((resp) => {
        if (resp == 'confirm') {
        return this.modalService.cancel();
        }
      })
  }
  confirm() {
    return this.modalService.confirm({});
  }
}
