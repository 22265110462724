import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';

@Component({
  selector: 'app-travel-card',
  templateUrl: './travel-card.component.html',
  styleUrls: ['./travel-card.component.scss'],
})
export class TravelCardComponent implements OnInit {
  @Input() travelData = [];
  @Input() fromComponent;
  @Input() status = 'pub';
  world_counrties_flags = '../../../../assets/world-countries/flags/';
  p: number = 1;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private apiService: ApiService,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  goToOffers(item) {
    this.router.navigate(['account/offers'], {
      queryParams: { type: 'travel', id: item.id, status: this.status },
    });
  }
  deleteTravel(item) {
    const msg = this.translateService.instant('areYouSur');
    const cancelText = this.translateService.instant('cancel');
    const confirmText = this.translateService.instant('yes');
    this.toastService
      .presentAlert(msg, cancelText, confirmText)
      .then((resp) => {
        const msg = this.translateService.instant('travelDeleted');
        if (resp == 'confirm') {
          this.apiService.deleteTravel(item['uuid']).subscribe((x) => {
            if (x == null) {
              this.toastService.presentToast('top', msg, 'success');
              this.router.navigate(['account']);
            }
          });
        }
      });
  }
}
