import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HandleDateService } from '../date-handle-service/handle-date.service';
@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  dataError;
  pwdPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$';

  constructor(
    private translateService: TranslateService,
    private handleDateService: HandleDateService
  ) {
    this.translateService.get('errorForm').subscribe((e) => {
      this.dataError = e;
    });
  }

  getErrorMessage(ctrl: string, form: FormGroup) {
    const aFormGroup = form as FormGroup;

    if (aFormGroup.get(ctrl).hasError('required')) {
      return this.dataError['required'];
    }
    if (aFormGroup.get(ctrl).hasError('email')) {
      return this.dataError['email'];
    }
    if (aFormGroup.get(ctrl).hasError('minlength')) {
      const requiredLength =
        aFormGroup.get(ctrl).errors['minlength']['requiredLength'];
      return (
        this.dataError['minlength'] +
        ' ' +
        requiredLength +
        ' ' +
        this.dataError['caracters']
      );
    }
    if (aFormGroup.get(ctrl).hasError('maxlength')) {
      const requiredLength =
        aFormGroup.get(ctrl).errors['maxlength']['requiredLength'];
      return (
        this.dataError['maxlength'] +
        ' ' +
        requiredLength +
        ' ' +
        this.dataError['caracters']
      );
    }
    if (aFormGroup.get(ctrl).hasError('min')) {
          const requiredLength =
            aFormGroup.get(ctrl).errors['min']['min'];
            
          return (
            this.dataError['min'] +
            ' ' +
            requiredLength
            
          )
    }
    if (aFormGroup.get(ctrl).hasError('max')) {
      return this.dataError['max'];
    }
    if (aFormGroup.get(ctrl).hasError('noWhitespaceValidator')) {
      return this.dataError['noWhitespaceValidator'];
    }
    if (aFormGroup.get(ctrl).hasError('whitespace2FirstLetter')) {
      return this.dataError['whitespace2FirstLetter'];
    }
    if (aFormGroup.get(ctrl).hasError('onlyInteger')) {
      return this.dataError['onlyInteger'];
    }
    if (aFormGroup.get(ctrl).hasError('matchingError')) {
      return this.dataError['matchingError'];
    }
    if (aFormGroup.get(ctrl).hasError('pattern')) {
      return this.dataError['pattern'];
    }
    if (aFormGroup.get(ctrl).hasError('invalidAutocompleteObject')) {
      return this.dataError['invalidAutocompleteObject'];
    }
    if (aFormGroup.get(ctrl).hasError('phone')) {
      return this.dataError['phone'];
    }
    if (aFormGroup.get(ctrl).hasError('not18years')) {
      return this.dataError['not18years'];
    }
  }
  passwordMatch(group: FormGroup) {
    const password = group.get('password').value;
    const confirm = group.get('confirmpassword').value;
    return password === confirm ? null : { matchingError: true };
  }
  matchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }
  public onlyDigits(control: FormControl) {
    const regex = new RegExp('^[0-9]*$');
    let text = control.value;
    let isValid = regex.test(text);

    return isValid ? null : { onlyInteger: true };
  }
  public noWhitespaceValidator2FirstLetter(control: FormControl) {
    let text = control.value;
    let isValid = text.length === 0 || text.trim().length >= 2;

    return isValid ? null : { whitespace2FirstLetter: true };
  }
  public noWhitespaceValidator(control: FormControl) {
    let isWhitespace = true;
    if (/\s/g.test(control.value)) {
      isWhitespace = false;
    }
    const isValid = isWhitespace;

    return isValid ? null : { whitespace: true };
  }
  buildToFormData(obj): FormData {
    const formData = new FormData();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const element = obj[key];

        formData.append(key, element);
      }
    }
    return formData;
  }
}
