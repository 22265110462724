import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { CardHomeComponent } from 'src/app/components/shared/card-home/card-home.component';
import { ConfirmDeliveryComponent } from 'src/app/components/shared/confirm-delivery/confirm-delivery.component';
import { DialogTravelFormComponent } from 'src/app/components/shared/dialog-travel-form/dialog-travel-form/dialog-travel-form.component';
import { KycLightComponent } from 'src/app/components/shared/kyc-light/kyc-light.component';
import { LoginFormComponent } from 'src/app/components/shared/login-form/login-form.component';
import { MaintenanceComponent } from 'src/app/components/shared/maintenance/maintenance.component';
import { MakeOfferComponent } from 'src/app/components/shared/make-offer/make-offer.component';
import { ModalLoginSignupComponent } from 'src/app/components/shared/modal-login-signup/modal-login-signup.component';
import { ModalVideoComponent } from 'src/app/components/shared/modal-video/modal-video.component';
import { OfferDetailsComponent } from 'src/app/components/shared/offer-details/offer-details.component';
import { PaymentMadeComponent } from 'src/app/components/shared/payment-made/payment-made.component';
import { PopoverComponent } from 'src/app/components/shared/popover/popover.component';
import { ShoppingCardComponent } from 'src/app/components/shared/shopping-card/shopping-card.component';
import { SignUpFormComponent } from 'src/app/components/shared/sign-up-form/sign-up-form.component';
import { SocialAuthComponent } from 'src/app/components/shared/social-auth/social-auth.component';
import { StripeConnectComponent } from 'src/app/components/shared/stripe/stripe-connect/stripe-connect.component';
import { StripePaymentElementComponent } from 'src/app/components/shared/stripe/stripe-payment-element/stripe-payment-element.component';
import { TravelCardComponent } from 'src/app/components/shared/travel-card/travel-card/travel-card.component';
import { TravelFormComponent } from 'src/app/components/shared/travel-form/travel-form.component';
import { UploadFileComponent } from 'src/app/components/shared/upload-file/upload-file.component';
import { TravelPage } from 'src/app/components/travel/travel.page';
import { UserSafeDetailsComponent } from 'src/app/components/user-safe-details/user-safe-details.component';
import { SwiperModule } from 'swiper/angular';
import { MaterialModule } from '../material/material.module';
import { TranslationModule } from '../translation/translation/translation.module';
import { ModalSearchProductComponent } from './../../components/shared/modal-search-product/modal-search-product.component';
import { ModalSpinnerComponent } from './../../components/shared/modal-spinner/modal-spinner.component';
import { SendOkComponent } from './../../components/shared/send-ok/send-ok.component';
import { BaseDirectiveModule } from './base.directive.module';
import { ModalConvinceComponent } from 'src/app/components/shared/modal-convince/modal-convince.component';
import { TokenConnexionComponent } from 'src/app/components/shared/token-connexion/token-connexion.component';



//ici c'est le module  qui doit être dans tous les modules 
const BASE_MODULES = [
  CommonModule,
  RouterModule,
  TranslationModule,
  LayoutModule,
  SwiperModule,
  ShowHidePasswordModule,
  NgxPaginationModule,
  BaseDirectiveModule,
];
const BASE_DECLARATIONS = [
  UploadFileComponent,
  MaintenanceComponent,
  CardHomeComponent,
  ModalSearchProductComponent,
  SendOkComponent,
  ShoppingCardComponent,
  TravelCardComponent,
  ModalSpinnerComponent,
  OfferDetailsComponent,
  ConfirmDeliveryComponent,
  MakeOfferComponent,
  PopoverComponent,
  StripeConnectComponent,
  StripePaymentElementComponent,
  ModalVideoComponent,
  ModalLoginSignupComponent,
  SocialAuthComponent,
  SignUpFormComponent,
  LoginFormComponent,
  KycLightComponent,
  TravelFormComponent,
  TravelPage,
  DialogTravelFormComponent,
  PaymentMadeComponent,
  ShoppingCardComponent,
  UserSafeDetailsComponent,
  ModalConvinceComponent,
  TokenConnexionComponent
];

@NgModule({
  declarations: [BASE_DECLARATIONS],
  imports: [CommonModule, BASE_MODULES],
  exports: [BASE_MODULES, BASE_DECLARATIONS],
})
export class BaseModule {}
