<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"reviews.title" | translate }}</ion-label>


    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
  <ion-grid fixed class="my-container-500 ">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="rating">
      
        <input type="radio" name="rating" value="5" id="5" formControlName="rating"><label for="5">☆</label>
        <input type="radio" name="rating" value="4" id="4" formControlName="rating"><label for="4">☆</label>
        <input type="radio" name="rating" value="3" id="3" formControlName="rating"><label for="3">☆</label>
        <input type="radio" name="rating" value="2" id="2" formControlName="rating"><label for="2">☆</label>
        <input type="radio" name="rating" value="1" id="1" formControlName="rating"><label for="1">☆</label>
      </div>
      <mat-error *ngIf="form.get('rating').invalid">{{"reviews.error" | translate }}</mat-error>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{"reviews.message" | translate }}</mat-label>
        <textarea matInput
          placeholder='{{"reviews.placeholder" | translate }}'
          rows="6" cols="45" formControlName="comments"></textarea>
      </mat-form-field>
      
    
      <ion-button  expand="block" [disabled]="form.invalid" (click)="onSubmit()">
        {{"toSend" | translate}}
      </ion-button>
    </form>

  </ion-grid>
