<ion-row fxLayout="row" fxLayoutAlign="center start" *ngIf="shoppingData">
  <ion-col size="12" *ngFor="let item of shoppingData | paginate: { itemsPerPage: 10, currentPage: p }">
    <ion-card>
      <ion-row>
        <ion-col size="12" class="ion-no-padding">
          <ion-chip (click)="userProfile(item['user_safe_data']['user_uuid'])">
            <ion-avatar>
              <img alt="avatar" [src]="item['user_safe_data']['avatar']" [appPhotoMissing]="'missing-avatar.png'" />
            </ion-avatar>
            <ion-label class="font-size-12">
              {{item['user_safe_data']['first_name']}}
              {{item['user_safe_data']['last_name']}} -
              <ion-icon name="star"></ion-icon>
              <ion-label class="font-size-12">{{item['user_safe_data']['rating']}}/5</ion-label> -
              <ion-label class="font-size-12">{{item['user_safe_data']['review_receiver_user'].length}}

                {{"ads.card.reviews" | translate | lowercase}}

              </ion-label>

            </ion-label>
          </ion-chip>

        </ion-col>
        <ion-col size="12" class="ion-no-padding">
          <div>
            <ion-card-header>
              <ion-card-title class="ion-text-nowrap">{{item['product']['product_title']}}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <div fxLayout="row" fxLayoutAlign="end start">
                <div>
                  <ion-thumbnail slot="start" class="thumbnail-16">
                    <ion-img [src]="item['product']['product_images'][0]['image']" [appPhotoMissing]="'no-image.png'">
                    </ion-img>
                  </ion-thumbnail>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div>{{"ads.card.tobuy" | translate | capitalize}}</div>
                <div><a href="{{item['product']['product_link']}}" target="_blank"
                    rel="noopener noreferrer">{{item['product']['product_link_domain']}}</a>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div>{{"ads.card.quantity" | translate | capitalize}}</div>
                <div>{{item['quantity']}}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div>{{"ads.card.unitPrice" | translate | capitalize}}</div>
                <div>{{item['product']['product_price']}}
                  {{item['product']['product_price_currency']}}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="row" fxLayoutAlign="start center">{{"ads.card.totalPrice" | translate | capitalize}}
             
                    <mat-icon class="info" (click)="displayTooltip()">info</mat-icon>

                </div>
                <div>{{item['estimate']['estimate_price']}}
                  {{item['estimate']['product_price_currency']}}</div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div class="reward">{{"ads.card.reward" | translate | capitalize}}</div>
                <div class="reward">{{item['estimate']['estimate_reward']}}
                  {{item['estimate']['product_price_currency']}}</div>
              </div>
              <div [ngStyle]="{'margin-top': '15px'}">
                <div>
                  <ion-label>
                      <img  *ngIf="item['from_city']['flag'] " alt="country" class="flag"
                        src="{{world_counrties_flags}}{{item['from_city']['flag']}}" [appPhotoMissing]="'no-image.png'" />
                      <span>{{item['from_city']['display_name']}}</span></ion-label>
                  <div>
                    <ion-icon name="arrow-down-outline"></ion-icon>
                  </div>
                    <ion-label>
                      <img matPrefix *ngIf="item['to_city']['flag'] " alt="country" class="flag"
                        src="{{world_counrties_flags}}{{item['to_city']['flag']}}" [appPhotoMissing]="'no-image.png'" />
                      {{item['to_city']['display_name']}}
                    
                    </ion-label>
                </div>
              </div>
            </ion-card-content>
            <ion-row>
              <ion-col size="12" [ngSwitch]="fromComponent">
                    <ion-button *ngSwitchCase="'UserSafeDetailsComponent'" expand="block" (click)="goToDetails(item['uuid'])">{{"ads.card.todoOffer" | translate |
                      capitalize}}
                    </ion-button>
                    <div *ngSwitchCase="'UserShoppingsComponent'">
                      <ng-container *ngIf="item['offer_shopping'] && item['offer_shopping'].length > 0; else elseTemplate">
                        
                        <ion-button  expand="block" (click)="goToOffer(item)">{{"account.userShoppings.offers.goToOffersTextButt" | translate |
                          capitalize}}
                        </ion-button>              
                        
                      </ng-container>
                      <ng-template #elseTemplate>
                        <ng-container *ngIf="!item.instantaneous; else elseTemplate">
                          <ion-button expand="block" disabled="true">{{"account.userShoppings.offers.notOffersTextButt" | translate |
                            capitalize}}
                          </ion-button>
                          <ion-button expand="block" color="danger" (click)="deleteShopping(item)">{{"toDelete" | translate |
                            capitalize}}
                          </ion-button>
                          
                        </ng-container>
                        <ng-template #elseTemplate>
                                  <ion-button expand="block"  color="success" (click)="instantaneaous(item)">{{"instantaneaous" | translate |
                                    capitalize}}
                                  </ion-button>
                                  <ion-button expand="block" color="danger" (click)="refund(item)">{{"refund" | translate |
                                    capitalize}}
                                  </ion-button>
                          
                        </ng-template>
                        
                        

                      </ng-template>
                          
                    </div>
                    <div *ngSwitchCase="'UserShoppingsComponentExp'">
                      <ng-container *ngIf="item['offer_shopping'] && item['offer_shopping'].length > 0; else elseTemplate">
                        
                        <ion-button  expand="block" (click)="goToOffer(item)">{{"account.userShoppings.offers.goToOffersTextButt" | translate |
                          capitalize}}
                        </ion-button>              
                        
                      </ng-container>
                      <ng-template #elseTemplate>
                        <ng-container *ngIf="!item.instantaneous; else elseTemplate">
                          <ion-button expand="block" disabled="true">{{"account.userShoppings.offers.notOffersTextButt" | translate |
                            capitalize}}
                          </ion-button>
                          <ion-button expand="block" color="danger" (click)="deleteShopping(item)">{{"toDelete" | translate |
                            capitalize}}
                          </ion-button>
                          
                        </ng-container>
                        <ng-template #elseTemplate>
                                  <ion-button expand="block"  color="success" (click)="instantaneaous(item)">{{"instantaneaous" | translate |
                                    capitalize}}
                                  </ion-button>
                                  <ion-button expand="block" color="danger" (click)="refund(item)">{{"refund" | translate |
                                    capitalize}}
                                  </ion-button>
                          
                        </ng-template>
                        
                        

                      </ng-template>
                             

                          
                    </div>
                    <div *ngSwitchCase="'UserShoppingsComponentOrder'">
                        <ion-button expand="block" color="success" (click)="displayingCode(item)">{{"account.userOrders.confirmReceive" |
                          translate |
                          capitalize}}
                        </ion-button>
                        <ion-button expand="block" (click)="orderDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                            <ion-button expand="block" fill="outline" (click)="messageOffer(item)">{{"message" | translate |
                              capitalize}}
                            </ion-button>
                          
                         

                    </div>
                    <div *ngSwitchCase="'UserTravelsComponentDelivery'">
                      <ion-button expand="block" color="success" (click)="sendingCode(item)">{{"account.userOrders.confirmDelivery" |
                        translate |
                        capitalize}}
                      </ion-button>
                        <ion-button expand="block" (click)="orderDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                            <ion-button expand="block" fill="outline" (click)="messageOffer(item)">{{"message" | translate |
                              capitalize}}
                            </ion-button>
                            <ion-button expand="block" color="danger" (click)="cancelDelivery(item)">{{"cancel" | translate |
                            capitalize}}
                            </ion-button>
                           

                    </div>
                    <div *ngSwitchCase="'UserTravelsComponentSuccessDelivery'">
                        <ion-button expand="block" (click)="orderDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                        <ng-container *ngIf="item['display_review_button']; else elseTemplate">
                          <ion-button expand="block" fill="outline" (click)="sendAnReviewByTraveler(item)">{{"userReviews.noteShopper" | translate
                            |
                            capitalize}}
                          </ion-button>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <ion-button expand="block" disabled="true">{{"disabledReview" | translate
                            |
                            capitalize}}
                          </ion-button>
                        </ng-template>
                        
                           

                    </div>
                    <div *ngSwitchCase="'UserShoppingsComponentDelivered'">
                        <ion-button expand="block" (click)="orderDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                                <ng-container *ngIf="item['display_review_button']; else elseTemplate">
                                <ion-button expand="block" fill="outline" (click)="sendAnReviewByShopper(item)">{{"userReviews.noteTraveler" | translate
                                  |
                                  capitalize}}
                                </ion-button>
                                </ng-container>
                                <ng-template #elseTemplate>
                                  <ion-button expand="block" disabled="true">{{"disabledReview" | translate
                                    |
                                    capitalize}}
                                  </ion-button>
                                </ng-template>

                    </div>

                      <div *ngSwitchCase="'UserPayoutsComponentOrder'">
                        <ion-button expand="block" (click)="payoutShopperDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                      </div>
                      <div *ngSwitchCase="'UserPayoutsComponentDelivery'">
                        <ion-button expand="block" (click)="payoutTravelerDetails(item)">{{"account.userOrders.inProgressOrder" | translate |
                          capitalize}}
                        </ion-button>
                      </div>
              </ion-col>
            </ion-row>
            <ion-footer [ngStyle]="{'padding': '16px'}">
              <div *ngIf=" item['offer_shopping'] && fromComponent != 'UserOffersComponent' ">{{item['offer_shopping'].length}} {{"ads.card.inProgressOffers" | translate |
                capitalize}}</div>
              <div></div>
              <div [ngSwitch]="fromComponent">
              <div *ngSwitchCase="'UserShoppingsComponentOrder'">
                    <div *ngIf="item['product_purchased']" fxLayout="row" fxLayoutAlign="space-between start" >
                      <div>{{"account.userOrders.purchasedProduct" | translate | capitalize}} </div>
                      <div >{{"yes" | translate}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                      <div>{{"account.userOrders.expectedDelivery" | translate | capitalize}} </div>
                      <div>{{item['up_to'] | date:'longDate'}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign=" center">
                    <ion-text><a  fxLayout="row" fxLayoutAlign=" center" (click)="deliveryBy(item['others']['offer']['travel']['user_safe_data']['user_uuid'])"
                        class="forward-howitworks">{{"account.userOrders.deliveryBy" | translate |
                        capitalize}}
                        {{item['others']['offer']['travel']['user_safe_data']['first_name']}}{{item['others']['offer']['travel']['user_safe_data']['last_name']}}
                                        
                      </a></ion-text>
                    </div>
                     
              </div>
              <div *ngSwitchCase="'UserTravelsComponentSuccessDelivery'">
                    <div *ngIf="item['product_purchased']" fxLayout="row" fxLayoutAlign="space-between start" >
                      <div>{{"account.userOrders.purchasedProduct" | translate | capitalize}} </div>
                      <div >{{"yes" | translate}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                      <div>{{"account.userOrders.delivered_at" | translate | capitalize}} </div>
                      <div>{{item['up_to'] | date:'longDate'}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign=" center">
                    <ion-text><a  fxLayout="row" fxLayoutAlign=" center" (click)="deliveryBy(item['others']['offer']['travel']['user_safe_data']['user_uuid'])"
                        class="forward-howitworks">{{"account.userOrders.deliveryBy" | translate |
                        capitalize}}
                        {{item['others']['offer']['travel']['user_safe_data']['first_name']}}{{item['others']['offer']['travel']['user_safe_data']['last_name']}}
                                        
                      </a></ion-text>
                    </div>
                     
              </div>
              <div *ngSwitchCase="'UserShoppingsComponentDelivered'">
                    <div *ngIf="item['product_purchased']" fxLayout="row" fxLayoutAlign="space-between start" >
                      <div>{{"account.userOrders.purchasedProduct" | translate | capitalize}} </div>
                      <div >{{"yes" | translate}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                      <div>{{"account.userOrders.delivered_at" | translate | capitalize}} </div>
                      <div>{{item['up_to'] | date:'longDate'}}</div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign=" center">
                    <ion-text><a  fxLayout="row" fxLayoutAlign=" center" (click)="deliveryBy(item['others']['offer']['travel']['user_safe_data']['user_uuid'])"
                        class="forward-howitworks">{{"account.userOrders.deliveryBy" | translate |
                        capitalize}}
                        {{item['others']['offer']['travel']['user_safe_data']['first_name']}}{{item['others']['offer']['travel']['user_safe_data']['last_name']}}
                                        
                      </a></ion-text>
                    </div>
                     
              </div>
              <div *ngSwitchCase="'UserPayoutsComponentOrder'">

                        <div fxLayout="row" fxLayoutAlign="space-between start">
                          <div>{{"status" | translate | capitalize}} </div>
                          <div>{{item['status_label']}}</div>
                        </div>
              </div>
              <div *ngSwitchCase="'UserPayoutsComponentDelivery'">

                        <div fxLayout="row" fxLayoutAlign="space-between start">
                          <div>{{"status" | translate | capitalize}} </div>
                          <div>{{item['status_label']}}</div>
                        </div>
              </div>

              <div *ngSwitchDefault>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                  <div>{{"ads.card.toDeliveryBefore" | translate | capitalize}} </div>
                  <div>{{item['up_to'] | date:'longDate'}}</div>
                </div>
              
              </div>
            </div>



            </ion-footer>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </ion-col>
      <pagination-controls class="my-pagination" (pageChange)="p = $event" [autoHide]="true" previousLabel='{{"previous" | translate | capitalize}}' nextLabel='{{"next" | translate | capitalize}}'></pagination-controls>

</ion-row>