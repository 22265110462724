
<ion-content >
<ion-grid fixed >
  <div  fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'margin-top': '50px'}">
     <div>
        <h3 class="ion-text-center">{{"errorComponent.oups" | translate }}:(</h3>
        <h4 class="ion-text-center">{{"errorComponent.sorry" | translate }}</h4>
        <p class="ion-text-center">
          {{"errorComponent.contact" | translate }}
        </p>
        <div class="ion-text-center"><ion-button color="primary" fill="outline"  [routerLink]="['/home']">{{"sendOk.homeButton" | translate | capitalize}}</ion-button></div>
        <div class="ion-text-center" [ngStyle]="{'margin': '15px', 'font-size':'20px'}"><a color="primary" fill="outline" [href]="mailTo">
          {{email  | lowercase }}</a></div>
     </div>
    </div> 
</ion-grid>
</ion-content>