import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { environment } from 'src/environments/environment';
declare const Stripe; //declaration de Stripe
@Component({
  selector: 'app-stripe-payment-element',
  templateUrl: './stripe-payment-element.component.html',
  styleUrls: ['./stripe-payment-element.component.scss'],
})
export class StripePaymentElementComponent implements OnInit {
  modalData; //cela vient du modal voir le modalService
  stripe = Stripe(environment.stripe_public_key);
  amount: number;
  total: number;
  currency = environment.currency;
  frontendUrl = environment.frontend_url;
  is_beta_test = environment.is_beta_test;
  form: FormGroup;
  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    const offer_uuid = this.modalData.data['uuid'];
    this.apiService.acceptOffer(offer_uuid).subscribe((r) => {
      if (r && r['client_secret']) {
        this.total = r['data']['total'];

        this.setupStripe(r['client_secret']);
      }
    });
    // this.getForm(offer_uuid);
  }
  getForm(offer_uuid) {
    this.form = this.fb.group({
      coupon_code: this.fb.control('', [
        // Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
      offer_uuid: this.fb.control(offer_uuid),
    });
  }
  SendCouponCode() {
    console.log(this.form.value);
    
  }
  getErrorMessage(ctrl) {
    return this.utilsService.getErrorMessage(ctrl, this.form);
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  confirm() {
    return this.modalService.confirm({});
  }

  setupStripe(CLIENT_SECRET) {
    const options = {
      clientSecret: CLIENT_SECRET,
      // Fully customizable with appearance API.
      appearance: {
        theme: 'flat',
        variables: { colorPrimaryText: '#262626' },
      },
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
    };
    const elements = this.stripe.elements(options);
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');
    const form = document.getElementById('payment-form');

    form.addEventListener('click', async (event) => {
      event.preventDefault();

      const { error } = await this.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: this.frontendUrl + 'payment-made',
        },
      });

      if (error) {
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      } else {
      }
    });
  }
}
