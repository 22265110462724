import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appPhotoMissing]',
})
export class PhotoMissingDirective {
  imagesFolder= '../../assets/images/'
  @Input() appPhotoMissing: string ; // la photo que l'on veut afficher en cas d'erreur
  defaulNotFoundImage = 'no-image.png'
  constructor(private el: ElementRef) {}

  @HostListener('error')
  private onError() {
   if (
     this.appPhotoMissing &&
     this.appPhotoMissing != undefined &&
     this.appPhotoMissing != null &&
     this.appPhotoMissing != ''
   ) {
   }else{
    this.appPhotoMissing = this.defaulNotFoundImage;
   }
    this.el.nativeElement.src =  this.imagesFolder + this.appPhotoMissing;
  }
}
