import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HTMLIonOverlayElement } from '@ionic/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private isOpen = new BehaviorSubject<boolean>(false);
  getisOpen$ = this.isOpen.asObservable();
  setisOpen(x: boolean) {
    this.isOpen.next(x);
  }
  currentisOpen(): Boolean {
    return this.isOpen.value;
  }
  constructor(private modalCtrl: ModalController) {}
  async openModal(component, modalData) {
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: {
        //passer des data au component modal
        modalData: modalData,
        isModal: true,
      },
      cssClass: 'my-modal',
      backdropDismiss: false,
    });
    modal.present();
    this.setisOpen(true);
    return await modal.onWillDismiss();
  }

  cancel() {
    this.setisOpen(false);
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  decline(confirmModalData) {
    this.setisOpen(false);
    return this.modalCtrl.dismiss(confirmModalData, 'decline');
  }
  confirm(confirmModalData) {
    this.setisOpen(false);
    return this.modalCtrl.dismiss(confirmModalData, 'confirm');
  }
  close() {
    //fermer le modal si ouvert sinon ne rien faire
    if (this.currentisOpen()) {
      //modal ouvert alors on peut fermer
      this.cancel();
    }
  }
  closeWithOutCancel() {
    //fermer le modal si ouvert sinon ne rien faire
    if (this.currentisOpen()) {
      //modal ouvert alors on peut fermer
      return this.modalCtrl.dismiss(null, null);
    }
  }
  async openModalSpinner(component, modalData) {
    this.setisOpen(true);
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: {
        //passer des data au component modal
        modalData: modalData,
        isModal: true,
      },
      cssClass: 'my-modal-spinner',
      backdropDismiss: false,
    });
    modal.present();
    return await modal.onWillDismiss();
  }
  async openModalVideo(component, modalData) {
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: {
        //passer des data au component modal
        modalData: modalData,
        isModal: true,
      },
      cssClass: 'my-modal-video',
    });
    modal.present();
    this.setisOpen(true);
    return await modal.onWillDismiss();
  }
  finish() {
    //fermer le modal si ouvert sinon ne rien faire
    if (this.currentisOpen()) {
      //modal ouvert alors on peut fermer
      this.modalCtrl.dismiss(null, 'finish');
      this.setisOpen(false);
    }
  }


closeAllModal (){
  // adjust selector to fit your needs
  const overlays = document.querySelectorAll('ion-alert, ion-action-sheet, ion-loading, ion-modal, ion-picker, ion-popover, ion-toast');
  const overlaysArr = Array.from(overlays) as HTMLIonOverlayElement[];
  overlaysArr.forEach(o => o.dismiss());
};
}
