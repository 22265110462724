<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div  fxLayout="row" fxLayoutAlign="center center" class="h-100">
    <div>
      <h3>{{"errorComponent.notFound" | translate }}:(</h3>

      <ion-button color="primary" fill="outline" [routerLink]="['/home']">{{"nav.home" | translate }}</ion-button>
    </div>
  </div>
</ion-content>