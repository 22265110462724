import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { delay, retryWhen, share, switchMap, take, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage-service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor(private localStorageService: LocalStorageService) {}
  private socket$: WebSocketSubject<any>;
  user_id;
  WS_ENDPOINT = environment.wsEndpoint;
  message$: Observable<any>;
  cuurent_user_id: number;
  subscription1: Subscription;
  subscription2: Subscription;

  private isWsError = new BehaviorSubject<boolean>(false);
  getisWsError$ = this.isWsError.asObservable();

  private msg = new BehaviorSubject<any>('');
  getMsg$ = this.msg.asObservable();
  setIsWsError(x: boolean) {
    this.isWsError.next(x);
  }
  setMsg(messages) {
    this.msg.next(messages);
  }

  public connectWebsocket(user_id: number): void {
    this.cuurent_user_id = user_id;
    this.subscription1 =
     this.localStorageService.getWstokenData$
     .pipe(
      switchMap(t=>{
        if (t) {
          //quand on se connecte
          return of(t)
        }
        else{
          //quand on rafrachit la page
          return this.localStorageService.get('ws_token');
        }
      })
     )
      .pipe(delay(1000))
      .subscribe((t) => {
        if (t) {
          if (!this.socket$ || this.socket$.closed === true) {
            this.socket$ = this.getNewWebSocket(user_id, t);
           this.subscription2 = this.socket$
              .pipe(
                share(),
                tap((data) => {
                  this.setIsWsError(false);
                }),
                retryWhen((errors) =>
                  errors.pipe(
                    tap((err) => {
                      this.setIsWsError(true);
                      console.error('Got error', err);
                    }),
                    delay(2000),
                    take(10)
                  )
                )
              )
              .subscribe((messages) => {
                this.setMsg(messages);
              });
          }
        }
      });
  }

  private getNewWebSocket(user_id: number,token) {
    this.WS_ENDPOINT = environment.wsEndpoint + user_id + '/';  
            const self = this
    return webSocket({
      url: this.WS_ENDPOINT,
      closeObserver: {
        next(event) {
          // to differentiate whether connection is lost or closed by unsubscribing
          if (!event.wasClean) {
          }
        },
      },
      protocol: ['ws_token', token],
    });
  }
  sendMessage(msg) {
    if (this.socket$) {
      this.socket$.next(msg);
    }
  }

  close() {
    try {
      this.socket$.complete();
      this.socket$.unsubscribe();
    } catch (e) {
      console.error('the error: ', e);
    }
    if (this.subscription2) {
       this.subscription2.unsubscribe()
    }
  }
}
