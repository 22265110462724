import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TravelPage } from 'src/app/components/travel/travel.page';
import { ModalService } from 'src/app/services/modal-service/modal.service';
@Component({
  selector: 'app-dialog-travel-form',
  templateUrl: './dialog-travel-form.component.html',
  styleUrls: ['./dialog-travel-form.component.scss'],
})
export class DialogTravelFormComponent implements OnInit {
  @ViewChild(TravelPage)
  travelPage: TravelPage;
  formValid = false;
  modalData; //cela vient du modal voir le modalService
  dataTravelItem;
  constructor(private modalService: ModalService, private fb: FormBuilder) {}

  ngOnInit() {
    this.dataTravelItem =  this.modalData;
    this.init();
  }
  ionViewWillEnter() {
    this.init();
  }
  init() {
  }
  formEvent(event) {
    if (event && event['uuid']) {
      this.modalService.confirm(event);
    }
  }

  send() {
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
}
