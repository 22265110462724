import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatawarehouseService {
  private loginData = new BehaviorSubject<any>({});
  getLoginData$ = this.loginData.asObservable();
  setloginData(x) {
    this.loginData.next(x);
  }
  currentLoginData() {
    return this.loginData.value;
  }

  private tokenData = new BehaviorSubject<any>({});
  gettokenData$ = this.tokenData.asObservable();
  settokenData(x) {
    this.tokenData.next(x);
  }

  private translateData = new BehaviorSubject<any>({});
  getTranslateData$ = this.translateData.asObservable();
  setTranslateData(x) {
    this.translateData.next(x);
  }
  private allWorldCountriesData = new BehaviorSubject<any>([]);
  getallWorldCountriesData$ = this.allWorldCountriesData.asObservable();
  setallWorldCountriesData(x) {
    this.allWorldCountriesData.next(x);
  }
  currentAllWorldCountriesData() {
    return this.allWorldCountriesData.value;
  }

  private availablesCountriesData = new BehaviorSubject<any>([]);
  getavailablesCountriesData$ = this.availablesCountriesData.asObservable();
  setavailablesCountriesData(x) {
    this.availablesCountriesData.next(x);
  }
  currentavailablesCountriesData() {
    return this.availablesCountriesData.value;
  }

  private OfferData = new BehaviorSubject<any>({});
  getOfferData$ = this.tokenData.asObservable();
  setOfferData(x) {
    this.OfferData.next(x);
  }

  private messageData = new BehaviorSubject<any>({
    offer_uuid: '',
    isRead: false,
  });
  getmessageData$ = this.messageData.asObservable();
  setmessageData(x) {
    this.messageData.next(x);
  }
  currentmessageData() {
    return this.messageData.value;
  }

  private allMessagesNotifData = new BehaviorSubject<any>(null);
  getallMessagesNotifData$ = this.allMessagesNotifData.asObservable();
  setallMessagesNotifData(x) {
    this.allMessagesNotifData.next(x);
  }
  currentallMessagesNotifData() {
    return this.allMessagesNotifData.value;
  }
  private openOfferdetails = new BehaviorSubject<any>(null);
  getopenOfferdetails$ = this.openOfferdetails.asObservable();
  setopenOfferdetails(x) {
    this.openOfferdetails.next(x);
  }
  currentopenOfferdetails() {
    return this.openOfferdetails.value;
  }

  private countMessageData = new BehaviorSubject<number>(0);
  getcountMessageData$ = this.countMessageData.asObservable();
  setcountMessageData(x) {
    this.countMessageData.next(x);
  }
  currentcountMessageData() {
    return this.countMessageData.value;
  }

  private orderData = new BehaviorSubject<any>({});
  getorderData$ = this.orderData.asObservable();
  setorderData(x) {
    this.orderData.next(x);
  }
  currentorderData() {
    return this.orderData.value;
  }

  constructor() {}
}
