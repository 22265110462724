<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"toDoOffer" | translate | capitalize}}

        </ion-label>
                <div>
                  <ion-button expand="block" shape="round" color="secondary" slot="start" size="small" [ngStyle]="{'width': '175px'}" (click)="convince()">
                    {{"to_convince" | translate | capitalize}}
                  </ion-button>
                </div>


    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>

  <ion-grid fixed class="my-container-500-without-padding-15 cust-padd ">
    <ion-note *ngIf="instantaneous" color="primary">
      {{"offerIntantaneousMsg" | translate | capitalize}}
    </ion-note>  
    <mat-stepper orientation="vertical" [linear]="isLinear" #stepper class="custom-stepper">
            <mat-step [stepControl]="formReward">
              <form [formGroup]="formReward">
                <ng-template matStepLabel>{{"adsDetails.form.reward.title" | translate | capitalize}}<br>
                  <span class="op-color">1/4</span></ng-template>
                <div class="form-container">
                  <div class="w-100 reward" [ngStyle]="{'margin-top': '15px'}">
                    <ion-label>{{"adsDetails.form.reward.title" | translate | capitalize}}<span class="red-color">*</span>: {{
                      formReward.get("reward").value }}
                      {{currency}}
                      <mat-icon class="info" (click)="displayTooltip()">info</mat-icon>
            
                    </ion-label>
                    <ion-range [pin]="true" [step]="0.5" [ticks]="true" [snaps]="true" formControlName="reward" [min]="min_reward"
                      [max]="max_reward"></ion-range>
                    <div *ngIf="!instantaneous">
                      <div *ngIf="!reward_is_disabled">
                        <ion-chip color="success" *ngIf="good_changes_reward">{{"adsDetails.goodChances" | translate |
                          capitalize}}</ion-chip>
                        <ion-chip color="warning" *ngIf="!good_changes_reward">{{"adsDetails.badChances" | translate |
                          capitalize}}</ion-chip>
                      </div>
                    </div>
            
            
                  </div>
                 
                  
            
            
                </div>
                <div>
                  <button mat-button matStepperNext>{{"next" | translate | capitalize}}</button>
                </div>
              </form>
            </mat-step>
      <mat-step [stepControl]="formTravel" >
        <form [formGroup]="formTravel">
          <ng-template matStepLabel >
            {{"adsDetails.form.travel.makeOfferTitle" | translate | capitalize}} <br>
            <span class="op-color">2/4</span>
          </ng-template>
          <div class="form-container">
            <div class="w-100" [ngStyle]="{'margin-top': '5px'}">
              <div *ngIf="(loginData$ | async) ">
                <ion-label>{{"adsDetails.form.travel.label" | translate | capitalize}} <span
                    class="red-color">*</span></ion-label>
                <mat-form-field appearance="outline" class="w-100 travel">
                  <mat-select formControlName="travel" [(value)]="selected" placeholder='{{"adsDetails.form.travel.placeholder" | translate | capitalize}}'>
                    <mat-select-trigger *ngIf="selected">
                      {{selected['from_city']['display_name']}}<br>
                      {{selected['to_city']['display_name']}}<br>
                      {{selected['travel_date'] | date:'longDate'}}
                    </mat-select-trigger>
                    <mat-option [value]="item" *ngFor="let item of travelOptions"
                      [ngStyle]="{'height': '5em', 'padding-top':'47px'}" class="option">
                      <div fxLayout="row">
                        <div>
                          <mat-icon [ngStyle]="{'margin': '0px'}">arrow_downward</mat-icon>
                        </div>
                        <div [ngStyle]="{'line-height': '1.6'}">
                          {{item['from_city']['display_name']}} <br>
                          {{item['to_city']['display_name']}} <br>
                          {{item['travel_date'] | date:'longDate'}}
                        </div>
                      </div>

                    </mat-option>
                  </mat-select>
                  <div matPrefix>
                    <mat-icon>arrow_downward</mat-icon>
                  </div>


                </mat-form-field>
                <div *ngIf="isDeliveryToCityError" class="invalid">
                  {{"isDeliveryToCityError" | translate}}
                </div>
                <ion-label>{{"or" | translate | uppercase}}</ion-label>
              </div>
              <ion-button (click)="createNewTravel()" expand="block" fill="outline" >
                {{"adsDetails.toCreateAtravel" | translate | capitalize}}
              </ion-button>
            </div>
          </div>
          <div>
                              <button mat-button matStepperPrevious>{{"previous" | translate | capitalize}}</button>

            <button mat-button matStepperNext >{{"next" | translate | capitalize}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="formDeliveryDate" >
        <form [formGroup]="formDeliveryDate">
          <ng-template matStepLabel>{{"adsDetails.form.delivery_date.title" | translate | capitalize}}<br>
          <span class="op-color">3/4</span></ng-template>
          <div class="form-container">
            <div class="w-100" [ngStyle]="{'margin-top': '15px'}">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>{{"adsDetails.form.delivery_date.title" | translate | capitalize}}</mat-label>
                <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="delivery_date" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker touchUi></mat-datepicker>
              </mat-form-field>
              <div *ngIf="isDeliveryDateError" class="invalid">
                {{"isDeliveryDateError" | translate}} <br>
                ({{formTravel.get('travel').value['travel_date'] | date:'longDate'}})

              </div>
            </div>

          </div>
          <div>
            <button mat-button matStepperPrevious>{{"previous" | translate | capitalize}}</button>
            <button mat-button matStepperNext>{{"next" | translate | capitalize}}</button>
          </div>
        </form>
      </mat-step>
      <mat-step >
        <ng-template matStepLabel>{{"adsDetails.recap" | translate | capitalize}}<br>
        <span class="op-color">4/4</span></ng-template>
        <div>
          <div [ngStyle]="{'margin-top': '20px'}">
            <ion-label class="recap">{{"adsDetails.invest" | translate | capitalize}}</ion-label>
            <ion-item>
              <ion-label>{{"adsDetails.product_link" | translate | capitalize}}</ion-label>
              <ion-label slot="end"><a href="{{shoppingData['product']['product_link']}}" target="_blank"
                rel="noopener noreferrer">{{shoppingData['product']['product_link_domain']}}</a>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>{{"adsDetails.price" | translate | capitalize}}</ion-label>
              <ion-label slot="end">{{shoppingData['estimate']['estimate_price']}}
                {{shoppingData['product']['product_price_currency']}}
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>{{"ads.card.quantity" | translate | capitalize}}</ion-label>
              <ion-label slot="end" id="quantity">{{shoppingData['quantity']}}
              </ion-label>
            </ion-item>
       
            <ion-item>
              <ion-label class="recap">{{"adsDetails.totalPayout" | translate | capitalize}}</ion-label>
              <ion-label slot="end">{{totalPayout}} {{shoppingData['product']['product_price_currency']}}
              </ion-label>
            </ion-item>
          </div>
          <div [ngStyle]="{'margin-top': '10px'}">
            <ion-label class="recap">{{"adsDetails.reward" | translate | capitalize}}</ion-label>
        
            <ion-item>
              <ion-label slot="end">{{formReward.get('reward').value }} {{shoppingData['product']['product_price_currency']}}
              </ion-label>
            </ion-item>
          </div>

        
        </div>
        
        <div class="sendB">
          <button mat-button matStepperPrevious>{{"previous" | translate | capitalize}}</button>
          <button mat-raised-button color="primary" (click)="send()">{{"toSend" | translate | capitalize}}</button>
        </div>
      </mat-step>
    </mat-stepper>
  </ion-grid>

</ion-content>
