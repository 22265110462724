import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { SocialAuthService } from 'src/app/services/social-auth-service/social-auth.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { KycLightComponent } from '../kyc-light/kyc-light.component';


@Component({
  selector: 'app-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.scss'],
})
export class SocialAuthComponent implements OnInit {
  @Input() dataFromParent;
  connectSuccess;

  constructor(
    private socialAuthService: SocialAuthService,
    private modalService: ModalService,
    private apiService: ApiService,
    private router: Router,
    private datawarehouseService: DatawarehouseService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    this.connectSuccess = this.translateService.instant('connectSuccess');
  }

  ngOnInit() {}
  authGoogle() {
    this.modalService.close();
    this.socialAuthService.signInWithGoogle().then((resp) => {
      if (resp && resp['user'] && resp['user']['uid']) {
        this.socialAuthService.getIdToken().then((res) => {
          if (res && res['token']) {
            if (this.dataFromParent == 'SignUpFormComponent') {
              // ça vient de l'inscription(on doit differencier de la connexion car on va demander le telephone)
              this.apiService
                .firebaseGoogleUserExist(res['token'])
                .subscribe((u) => {
                  if (u && u['user']) {
                    this.connexionOk(u);
                  } else {

                    this.modalService
                      .openModal(KycLightComponent, resp['user']['displayName'])
                      .then((result) => {
                        if (
                          result['role'] == 'confirm' &&
                          result['data'] &&
                          result['data']['phone_number']
                        ) {
                          const phone_number =
                            result['data']['phone_number']
                          this.sendToFireBaseSignUp(res, phone_number);
                        }
                      });
                  }
                });
            } else {
              this.sendToFireBaseLogin(res);
            }
          } else {
            const msg = this.translateService.instant('globalError.authError');
            this.toastService.presentToast('top', msg, 'danger');
          }
        });
      } else {
        const msg = this.translateService.instant('globalError.authError');
        this.toastService.presentToast('top', msg, 'danger');
      }
    });
  }

  connexionOk(x) {
    if (x && x['user'] && x['user']['email']) {
      const token = {
        access: x['access'],
        refresh: x['refresh'],
      };
      this.localStorageService.setToken('token', token);
      this.localStorageService.setWsToken('ws_token', x['ws_token']);
      this.datawarehouseService.setloginData(x);
      if (this.router.url.includes('error')) {
        //si erreur
        this.router.navigate(['home']);
      }
      if (this.router.url.includes('login')) {
        //si erreur
        this.router.navigate(['account']);
      }
      if (this.router.url.includes('signup')) {
        //si erreur
        this.router.navigate(['account']);
      }
    }
  }
  sendToFireBaseSignUp(resp, phone_number) {
    this.apiService
      .firebaseGoogleSignUp(resp['token'], phone_number)
      .subscribe((x) => {
        // factoriser cette partie avec login-form
        this.connexionOk(x);
      });
  }
  sendToFireBaseLogin(resp) {
    this.apiService.firebaseGoogleLogin(resp['token']).subscribe((x) => {
      // factoriser cette partie avec login-form
      if (x && x['user'] && x['user']['email']) {
        const token = {
          access: x['access'],
          refresh: x['refresh'],
        };
        this.localStorageService.setToken('token', token);
        this.localStorageService.setWsToken('ws_token', x['ws_token']);
        this.datawarehouseService.setloginData(x);
        if (this.router.url.includes('error')) {
          //si erreur
          this.router.navigate(['home']);
        } else {
          this.toastService.presentToast('top', this.connectSuccess, 'success');
        }
        if (this.router.url.includes('login')) {
          //si erreur
          this.router.navigate(['account']);
        }
      }
    });
  }
}
