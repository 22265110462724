<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"stripe_element.title" | translate | capitalize}} <br><a href="https://stripe.com/fr/connect" target="_blank" rel="noopener noreferrer">{{"stripe_more" | translate}}</a> </ion-label>


    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-grid fixed class="my-container-500-without-padding-15 cust-padd ">
    <ion-card *ngIf="is_beta_test">
      <ion-label> En mode Test</ion-label>
      <p>
        Utilisez cette carte pour tester un paiement réussi
        <ion-label color="success">4000000000000077</ion-label>
      </p>
      <p>
        Utilisez cette carte pour tester un paiement échoué
        <ion-label color="danger">4000000000000002</ion-label>
      </p>
      <p>
        Mettez une date d'expération supérieure à aujourd'hui et 123 pour le CVC
      </p>
    </ion-card>
    <!-- <ion-accordion-group>
      <ion-accordion value="first">
        <ion-item slot="header" color="light">
          {{"i_have_code_promo" | translate | capitalize}}
        </ion-item>
        <div class="ion-padding" slot="content">
        <form #f="ngForm" [formGroup]="form" (ngSubmit)="SendCouponCode()">
          <div class="w-100">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label class="required">
                          {{"promo_code" | translate | capitalize}}

              </mat-label>
              <input matInput placeholder="EX: ILI003" type="text" formControlName="coupon_code" />
        
              <mat-error *ngIf="form.get('coupon_code').invalid">{{
                getErrorMessage("coupon_code")
                }}</mat-error>
            </mat-form-field>
          </div>
          <ion-button type="submit" [disabled]="form.invalid" class="w-100">
            {{ "toSend" | translate | capitalize }}
          </ion-button>
        
        </form>
        </div>
      </ion-accordion>
    </ion-accordion-group> -->
    <form id="payment-form" [ngStyle]="{'margin-top': '15px'}">
      <div id="link-authentication-element">
      </div>
      <div id="payment-element">
      </div>
      <ion-button id="submit" color="success" expand="full" [ngStyle]="{'margin-top': '10px'}"  >
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Payez {{total}} {{currency}}</span> 
      </ion-button>
     
      <div id="payment-message" class="hidden"></div>
        <div id="error-message" >
        </div>
    </form>
  </ion-grid>
</ion-content>