import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { map } from 'rxjs/operators';
import { DatawarehouseService } from '../datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigInitService {
  //Service qui se lance avant le démarrage de l'app pour initialiser les valeurs
  private configData = new BehaviorSubject<any>([]);
  getConfigData$ = this.configData.asObservable();
  setConfigData(x) {
    this.configData.next(x);
  }
  currentConfigData() {
    this.configData.value;
  }
  default_language = APP_CONSTANTS.default_language;

  constructor(
    private translateService: TranslateService,
    private datawarehouseService: DatawarehouseService,
    private localStorageService: LocalStorageService
  ) {}

  init(): Observable<any> {
    this.localStorageService.init();
    this.translateService.use(this.default_language);

    return this.translateService.getTranslation(this.default_language).pipe(
      map((t) => this.datawarehouseService.setTranslateData(t)),
    );
  }
}
