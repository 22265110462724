import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BaseModule } from 'src/app/shared-module/base/base.module';
import { ErrorComponent } from './error-component/error.component';
import { ErrorHandlerService } from './error-handler-service/error-handler.service';
import { ErrorRoutingModule } from './error-routing';
import { NotFoundComponent } from './not-found/not-found.component';
import { BaseDirectiveModule } from 'src/app/shared-module/base/base.directive.module';
import * as Sentry from '@sentry/angular-ivy';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BaseModule,
    ErrorRoutingModule,
    BaseDirectiveModule,
  ],
  declarations: [ErrorComponent, NotFoundComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class ErrorModule {}
