<ion-grid fixed class="my-container-500 ">
  <div class="ion-text-right" *ngIf="!fromParentIsModal">
    <ion-chip  [outline]="true" (click)="signup()" *ngIf="!isLogin">{{"not_account" | translate | capitalize}}
    </ion-chip>
    <mat-divider></mat-divider>

  </div>

  <div class="ion-text-center">
    <h3 [ngStyle]="{'margin': '5px'}">{{ "signin" | translate}}</h3>

  </div>
  <app-social-auth [dataFromParent]="dataFromParent"></app-social-auth>
  <div fxLayout="row" fxLayoutAlign="center start" [ngStyle]="{'margin': '15px'}">
    {{"or" | translate }}
  </div>
  <form #f="ngForm" [formGroup]="form" (ngSubmit)="send()">
    <div class="w-100 ">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label class="required">
          {{"login.form.email.label" | translate | capitalize}}
        </mat-label>
        <input matInput placeholder='{{"login.form.email.placeholder" | translate | capitalize}}' type="email"
          formControlName="email" />

        <mat-error *ngIf="form.get('email').invalid">{{
          getErrorMessage("email")
          }}</mat-error>
      </mat-form-field>
    </div>
    <div class="w-100 ">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{"login.form.password.label" | translate | capitalize}}
        </mat-label>
        <input id="mysecretpassword" matInput
          placeholder='{{"login.form.password.placeholder" | translate | capitalize}}' required
          formControlName="password" type="password" showHideInput />
        <i matSuffix class="material-icons" showHideTrigger="mysecretpassword"
          [showHideStatus]="{ materialIcon: true, id: 'mysecretpassword' }"></i>
        <mat-error *ngIf="form.get('password').invalid">{{
          getErrorMessage("password")
          }}</mat-error>
      </mat-form-field>
    </div>

    <ion-button type="submit" [disabled]="form.invalid" class="w-100">
      {{"login.button" | translate | capitalize}}
    </ion-button>
  </form>
    <div [ngStyle]="{'margin-top': '15px'}" class="ion-text-end">
      <ion-chip [outline]="true" (click)="resetPassword()">{{"reset_password.title" | translate | capitalize}}
      </ion-chip>
    
    
    </div>
  <div class="ion-text-end" *ngIf="!fromParentIsModal" [ngStyle]="{'margin-top': '15px'}" >
        <ion-chip [outline]="true" (click)="signup()" *ngIf="!isLogin">{{"not_account" | translate | capitalize}}
        </ion-chip>
  </div>

<mat-divider>
</mat-divider>
</ion-grid>
<ion-grid fixed class="my-container-500 ">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="terms">
    <div>
      <ion-note [ngStyle]="{'font-size': '10px'}" color="primary">
        <a [routerLink]="['/extras/terms-of-use']" target="_blank">{{"termsOfService" | translate | uppercase}}</a>
      </ion-note>
    </div>
    <div>
      <ion-note color="primary" [ngStyle]="{'font-size': '10px'}">
        <a [routerLink]="['/extras/privacy-policy']" target="_blank"> {{"privacyAndSecurity" | translate | uppercase}}</a>
      </ion-note>
    </div>
  </div>
</ion-grid>