<ion-content>

  <ion-grid fixed class="my-container-500 send" [ngStyle]="{'margin-top': '30px'}">
    <h3 class="ion-text-center">
      {{"paymentMade.title" | translate | capitalize}}
    </h3>
    <div class="ion-text-center">
      <div>
        <ion-button   fill="outline" [routerLink]="['/account/shoppings']">
          {{"paymentMade.btnOrder" | translate | capitalize}}

        </ion-button>
      </div>


    </div>

  </ion-grid>

</ion-content>