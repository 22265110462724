import { ApiService } from 'src/app/services/api-service/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-user-safe-details',
  templateUrl: './user-safe-details.component.html',
  styleUrls: ['./user-safe-details.component.scss'],
})
export class UserSafeDetailsComponent implements OnInit {
  userUuid: any;
  dataSubscription: Subscription;
  shoppingSubscription: Subscription;
  travelSubscription: Subscription;
  userData;
  selectedIndex = 0; //active tab
  shoppingData: any = [];
  travelData: any = [];
  componentName = 'UserSafeDetailsComponent';

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private apiService: ApiService
  ) {}

  ngOnInit() {}
  ionViewWillEnter() {
    this.init();
  }
  init() {
    this.userUuid = this.route.snapshot.paramMap.get('uuid');
    this.componentName = 'UserSafeDetailsComponent';
    this.dataSubscription = this.apiService
      .getUserSafeDetail(this.userUuid)
      .subscribe((userData) => {
        this.userData = userData;
      });
  }
  selectedTabChange(event) {
    switch (event.index) {
      case 1:
        this.getshoppingUsers();
        break;
      case 2:
        this.getTravelUser();
        break;

      default:
        break;
    }
  }
  getshoppingUsers() {
    this.shoppingSubscription = this.apiService
      .getShoppingUser(this.userUuid)
      .subscribe((shoppingData) => {
        this.shoppingData = shoppingData;
      });
  }
  getTravelUser() {
    this.travelSubscription = this.apiService
      .getTravelUser(this.userUuid)
      .subscribe((travelData) => {
        this.travelData = travelData;
      });
  }
  back() {
    this.location.back();
  }
  ionViewWillLeave() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.shoppingSubscription) {
      this.shoppingSubscription.unsubscribe();
    }
    if (this.travelSubscription) {
      this.travelSubscription.unsubscribe();
    }
  }
}
