import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';


@Injectable({
  providedIn: 'root',
})
export class HandleDateService {
  format = 'YYYY-MM-DD';
  // minDate = moment().format('YYYY-MM-DD');
  // currentDate = moment().toDate();
  // currentDatePlus1Day = moment().add(1, 'day').toDate();
  minDate = dayjs().format('YYYY-MM-DD');
  currentDate = dayjs();
  currentDatePlus1Day = dayjs().add(1, 'day').toDate();
  currentDatePlus1month = dayjs().add(1, 'month').toDate();

  getDateToAddDay(day: number): Date {
    return dayjs().add(day, 'day').toDate();
  }
  getStringToDate(date_string) {
    if (date_string) {
      return dayjs(date_string, environment.formatDate).toDate();
    } else {
      return null;
    }
  }
  getDateToString(date) {
    return dayjs(date).format(this.format);
  }
  getDiffDate(date) {
    return dayjs().diff(date, 'year');
  }

  constructor() {}
}
