<ion-row fxLayout="row" fxLayoutAlign="center start" *ngIf="travelData">
  <ion-col size="12" *ngFor="let item of travelData | paginate: { itemsPerPage: 10, currentPage: p }">
    <ion-card>
      <ion-row>
        <ion-col size="12" class="ion-no-padding">
          <div>
            <ion-card-content>
              <ion-label>
                  <img matPrefix
                    *ngIf="item['from_city']['flag'] "
                    alt="country" class="flag" src="{{world_counrties_flags}}{{item['from_city']['flag']}}" [appPhotoMissing]="'no-image.png'" />
                <span>{{item['from_city']['display_name']}}</span></ion-label><br>
              <ion-icon name="arrow-down-outline"></ion-icon><br>
              <ion-label>
                <img matPrefix *ngIf="item['to_city']['flag'] " alt="country" class="flag" src="{{world_counrties_flags}}{{item['to_city']['flag']}}"
                  [appPhotoMissing]="'no-image.png'" />
                {{item['to_city']['display_name']}}

              </ion-label>
                    <ion-row>
                      <ion-col size="12" [ngSwitch]="fromComponent">
                        <div *ngSwitchCase="'UserTravelsComponent'">
                          <ng-container *ngIf=" item['offer_travel'] && item['offer_travel'].length > 0; else elseTemplate">
                            <ion-button  expand="block" 
                              (click)="goToOffers(item)">{{"account.userTravels.goToOffersTextButt" | translate |
                              capitalize}}
                            </ion-button>
                            
                          </ng-container>
                          <ng-template #elseTemplate>
                            <ion-button  expand="block" disabled="true"
                              >{{"account.userTravels.notOffersTextButt" | translate |
                              capitalize}}
                            </ion-button>
                              <ion-button expand="block" color="danger" (click)="deleteTravel(item)">{{"toDelete" | translate |
                                capitalize}}
                              </ion-button>
                            
                          </ng-template>
                          
                        </div>
                        
                        
                      </ion-col>
                    </ion-row>
    
            </ion-card-content>

            <ion-footer [ngStyle]="{'padding': '16px'}">
             
              <div *ngIf=" fromComponent != 'UserOffersComponent'">
                
                <div [ngSwitch]="fromComponent">
                <div *ngSwitchCase="'UserTravelsComponent'">
                  <div>
                    {{item['offer_travel'].length}} {{"account.userTravels.nbOfDeliveries" | translate
                    }}
                  </div>
                  <div>
                    {{item['offer_delivery'].length}} {{"account.userTravels.nbOfOrderToDelivery" | translate
                    }}
                  </div>
                
                </div>
                <div *ngSwitchDefault>
                  {{item['offer_travel'].length}} {{"account.userTravels.nbOfDeliveries" | translate
                  }}
                </div>
              </div>
              
            
            </div>
              <div fxLayout="row" fxLayoutAlign="space-between start">
                <div>{{"userDetails.travelDate" | translate | capitalize}} </div>
                <div>{{item['travel_date'] | date:'longDate'}}</div>
              </div>



            </ion-footer>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </ion-col>
  <pagination-controls class="my-pagination" (pageChange)="p = $event" [autoHide]="true"
    previousLabel='{{"previous" | translate | capitalize}}' nextLabel='{{"next" | translate | capitalize}}'>
  </pagination-controls>
</ion-row>