<ion-card >
  <div fxLayout="column">
    <div>
      <img [src]="data.image" loading="lazy" alt="photo" [appPhotoMissing]="'no-image.png'" [ngStyle]="{'height': '150px'}" />
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start">
   
   <div>
        <ion-chip color="primary">
          <ion-avatar>
            <img alt="avatar"  loading="lazy" [src]="data.traveler_avatar" class="avatar" [appPhotoMissing]="'no-image.png'"/>
          </ion-avatar>
          <ion-label>{{"traveler" | translate}}</ion-label>
        </ion-chip>
        <div>
          <ion-icon name="location" slot="start" color="primary"></ion-icon>

          <ion-note [ngStyle]="{'font-weight': '500'}">
            {{data.from_city}} </ion-note>
        </div>
        <div>
          <ion-note [ngStyle]="{'font-weight': '500'}">{{data.earned}} {{"earned" | translate}}   </ion-note>
        </div>
   </div>
      
        <ion-icon name="arrow-forward-outline"  size="large"></ion-icon>
    

     <div>
        <ion-chip class="my-chip-secondary">
          <ion-avatar>
            <img alt="avatar" loading="lazy" [src]="data.shopper_avatar" class="avatar" [appPhotoMissing]="'no-image.png'"/>
          </ion-avatar>
          <ion-label>{{"shopper" | translate}}</ion-label>
        </ion-chip>
          <div>
                      <ion-icon name="location" slot="start" color="secondary"></ion-icon>

          <ion-note [ngStyle]="{'font-weight': '500'}">{{data.to_city}}</ion-note>
          
          </div>
          <div>
          <ion-note [ngStyle]="{'font-weight': '500'}">{{data.saved}} {{"saved" | translate}}</ion-note>
          
          </div>
     </div>
    


  </div>
</ion-card>
