import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {
  constructor(
    private afAuth: AngularFireAuth, // Inject Firebase auth service
  ) {}

  async signInWithGoogle() {
    return await FirebaseAuthentication.signInWithGoogle();
  }
  async getIdToken(){
    return await FirebaseAuthentication.getIdToken();
  }
  async signInWithFacebook() {
    await FirebaseAuthentication.signInWithFacebook().then((resp) => {
    });
  }
  async signOut()  {
    return await FirebaseAuthentication.signOut();
  };

}
