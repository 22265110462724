import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  email = APP_CONSTANTS.email;
  mailTo = 'mailto:' + this.email;
  data;
  constructor(private activatedRoute: ActivatedRoute) {
    this.data =
      Object.keys(this.activatedRoute.snapshot.data).length >= 1
        ? this.activatedRoute.snapshot.data
        : '';
  }

  ngOnInit() {}
}
