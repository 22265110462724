<ion-content>

  <ion-grid fixed class="my-container-500 send">
    <h3 class="ion-text-center">
      <span [ngSwitch]="type">
        <div *ngSwitchCase="'offer'">
          {{"sendOk.msgOfferSendok.msg1" | translate | capitalize}} <br>
          <ion-note>{{"sendOk.msgOfferSendok.instantaneaous" | translate }}</ion-note>


        </div>
        <div *ngSwitchCase="'signUp'">
          {{"signup.signUpMessage.msg1" | translate | capitalize}} <br>
          <ion-note>{{"signup.signUpMessage.msg2" | translate | capitalize}}</ion-note>


        </div>
        <div *ngSwitchCase="'signUpModal'">
          {{"signup.signUpModalMessage.msg1" | translate | capitalize}} <br>
          <ion-note>{{"signup.signUpModalMessage.msg2" | translate}}</ion-note><br>
          <ion-note>{{"signup.signUpModalMessage.msg3" | translate }}</ion-note>


        </div>
        <div *ngSwitchCase="'activation'">
          {{"activation.msg1" | translate | capitalize}} <br>
          <ion-note>{{"activation.msg2" | translate | capitalize}}</ion-note>
        </div>
        <div *ngSwitchCase="'activationIsOk'">
          {{"activation.msg1" | translate | capitalize}} <br>
          <ion-note>{{"activation.msg2" | translate | capitalize}}</ion-note>
        </div>
        <div *ngSwitchCase="'resetPassword'">
          {{"resetPasswordOk" | translate | capitalize}} <br>
          <ion-note>{{"activation.msg2" | translate | capitalize}}</ion-note>
        </div>
        <div *ngSwitchCase="'confirmPassword'">
          {{"confirmPasswordOk" | translate | capitalize}} <br>
          <ion-note>{{"activation.msg2" | translate | capitalize}}</ion-note>
        </div>
                <div *ngSwitchCase="'stripe'">
                  {{"stripe_connect.onboarding_ok" | translate }} <br>
                </div>
        <div *ngSwitchDefault>
          {{"sendOk.msgSendok" | translate | capitalize}}
        </div>
      </span>

    </h3>
    <div class="ion-text-center">
      <div>
        <ion-button (click)="home()" color="secondary" fill="outline">
          {{"sendOk.homeButton" | translate | capitalize}}

        </ion-button>
      </div>
      <div>
        <ion-button (click)="shopping()" color="primary" fill="outline">
          {{"sendOk.shoppingButton" | translate | capitalize}}

        </ion-button>
      </div>
      <div>
        <ion-button (click)="accountButton()" color="primary" >
          {{"sendOk.accountButton" | translate | capitalize}}

        </ion-button>
      </div>


    </div>

  </ion-grid>

</ion-content>