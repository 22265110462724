import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api-service/api.service';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { HandleDateService } from 'src/app/services/date-handle-service/handle-date.service';
import { LocalStorageService } from 'src/app/services/local-storage-service/local-storage.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { environment } from 'src/environments/environment';
import { DialogTravelFormComponent } from '../dialog-travel-form/dialog-travel-form/dialog-travel-form.component';
import { ModalLoginSignupComponent } from '../modal-login-signup/modal-login-signup.component';
import { ModalConvinceComponent } from '../modal-convince/modal-convince.component';

@Component({
  selector: 'app-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.scss'],
})
export class MakeOfferComponent implements OnInit {
  travelOptions = [];
  modalData; //cela vient du modal voir le modalService
  form: FormGroup;
  formTravel: FormGroup;
  formReward: FormGroup;
  formTax: FormGroup;
  formOther: FormGroup;
  formDeliveryDate: FormGroup;
  shoppingTitle = '';
  currency = environment.currency;
  reward;
  minDate = this.handleDateService.minDate;
  good_changes_reward = true;
  aditionalTax: number = 0;
  totalPayout: number;
  price: number;
  shoppingData;
  min_reward = environment.minReward;
  max_reward = 150;
  termsOfUseCHecked = true;
  selected: any;
  instantaneous = false;
  reward_is_disabled = false; //quand le max_reward ==  min_reward

  isDeliveryDateError = false; //pour affichier l'erreur
  isDeliveryToCityError = false;

  loginData$ = this.datawarehouseService.getLoginData$.pipe(
    map((l) => {
      if (l && l['user']) {
        return true;
      } else {
        return false;
      }
    })
  );
  isLinear = true;

  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private handleDateService: HandleDateService,
    private datawarehouseService: DatawarehouseService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.shoppingData = this.modalData;
    this.instantaneous = this.shoppingData['instantaneous'];
    this.getForm();
    this.init();
    this.getUsersTravelsPublished();
  }
  getForm() {
    this.formTravel = this.fb.group({
      travel: this.fb.control('', [Validators.required]),
    });
    this.formReward = this.fb.group({
      reward: this.fb.control('', [
        Validators.required,
        Validators.min(this.min_reward),
      ]),
      is_tax: this.fb.control(true),
      additional_tax: this.fb.control(0, [
        Validators.required,
        Validators.min(0),
      ]),
      additional_tax_comments: this.fb.control(''),
    });
    this.formDeliveryDate = this.fb.group({
      delivery_date: this.fb.control(null, [Validators.required]),
    });
    this.formOther = this.fb.group({
      shopping: this.fb.control({ value: this.shoppingTitle, disabled: true }, [
        Validators.required,
      ]),
      terms: this.fb.control(false, [Validators.required]),
    });

    //Règle de gestion
    //si on change de travel - pour vérifier et mettre à jour le texte qui indique au user que
    //la destination de son voyage ne correspond pas au pays de la livraison du pays
    this.formTravel.get('travel').valueChanges.subscribe((v) => {
      if (v) {
        if (v['to_city']) {
          const country_id = v['to_city']['country'];
          if (country_id != this.modalData['to_city']['country']) {
            this.isDeliveryToCityError = true;
            this.formTravel.get('travel').setErrors({ incorrect: true });
          } else {
            this.isDeliveryToCityError = false;
            this.formTravel.get('travel').setErrors(null);
          }
        }
      }
    });
    //la date de livraison(on compare la date de livraison à la date de voyage)
    this.formDeliveryDate.get('delivery_date').valueChanges.subscribe((v) => {
      if (v && this.formTravel.get('travel').value['travel_date']) {
        const d = this.handleDateService.getDateToString(v);

        if (d > this.formTravel.get('travel').value['travel_date']) {
          this.isDeliveryDateError = false;
          this.formDeliveryDate.get('delivery_date').setErrors(null);
        } else {
          this.isDeliveryDateError = true;
          this.formDeliveryDate
            .get('delivery_date')
            .setErrors({ incorrect: true });
        }
      }
    });
    //si on changes le curseur du reward
    this.formReward.get('reward').valueChanges.subscribe((v) => {
      if (v > this.reward) {
        this.good_changes_reward = false;
      } else {
        this.good_changes_reward = true;
      }
    });
    //si on ajoute des frais supplémentaires
    this.formReward.get('additional_tax').valueChanges.subscribe((v) => {
      this.totalPayout = this.price + v;
    });
  }
  displayTooltip() {
    const msg = this.translateService.instant('adsDetails.form.reward.info');
    this.toastService.presentPopover(msg);
  }

  init() {
    this.shoppingTitle = this.shoppingData['product']['product_title'];
    this.max_reward = this.shoppingData['estimate']['max_reward'];
    this.min_reward = this.shoppingData['estimate']['min_reward'];
    this.reward = this.shoppingData['estimate']['estimate_reward'];
    this.price = +this.shoppingData['estimate']['estimate_price'];
    this.totalPayout = this.price;
    if (this.instantaneous) {
      this.reward = this.shoppingData['instantaneous_data']['reward'];
      this.formReward.get('reward').disable();
      this.formReward
        .get('additional_tax')
        .setValue(+this.shoppingData['instantaneous_data']['additional_tax']);
      this.formReward.get('additional_tax').disable();
    }
    this.formOther.get('shopping').setValue(this.shoppingTitle);
    this.formReward.get('reward').setValue(this.reward);
    this.formReward
      .get('reward')
      .addValidators(Validators.max(this.max_reward));
    if (
      this.min_reward == this.max_reward ||
      this.max_reward < this.min_reward
    ) {
      this.formReward.get('reward').disable();
      this.reward_is_disabled = true;
    }
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  send() {
    this.sendingFormValue();
  }
  convince() {
    this.modalService
      .openModal(ModalConvinceComponent, { from: 'traveler' })
      .then((res) => {});
  }
  storeOffer() {
    //ici on va stocker dans la base locale du navigateur, l'offre pour que si l'utilisateur n'a pas fait son onboarding
    //Cela evite au voyageur de resaissir encore les données pour envoyer son offre
    /**
     il faut noter qu'on va gérer un seul cas qui est celui de l'offre en cours. On va gérer les cas où le voyageur fait une offre et arrête et refait une offre . On va pas stocker toutes ces offres, on stocke uniquement la dernière
     */
    const valuesForm = {
      ...this.formTravel.value,
      ...this.formDeliveryDate.value,
      ...this.formReward.getRawValue(),
      ...this.formOther.value,
    };
    const data = {};
    data['shopping'] = this.shoppingData['id'];
    data['travel'] = valuesForm['travel']['id'];
    data['reward'] = valuesForm['reward'];
    data['additional_tax'] = valuesForm['additional_tax'];
    data['additional_tax_comments'] = valuesForm['additional_tax_comments'];
    data['delivery_date'] = this.handleDateService.getDateToString(
      valuesForm['delivery_date']
    );

    this.localStorageService.set('offer', data);
  }
  sendingFormValue() {
    const valuesForm = {
      ...this.formTravel.value,
      ...this.formDeliveryDate.value,
      ...this.formReward.getRawValue(),
      ...this.formOther.value,
    };
    if (valuesForm['delivery_date'] < valuesForm['travel']['travel_date']) {
      //on vérifie que la date de livraison du produit est supérieur ou égal à la date du voyage
      const msg = this.translateService.instant(
        'adsDetails.handleError.TravelDateIsSuperiorToDeliveryData'
      );
      return this.toastService.presentToast('top', msg, 'danger');
    }
    if (
      valuesForm['travel']['to_city']['country'] !=
      this.modalData['to_city']['country']
    ) {
      //si le pays d'arrivée est different du pays de livraison
      let msg = this.translateService.instant(
        'adsDetails.handleError.shopping_and_traveler_have_the_same_to_city'
      );
      return this.toastService.presentToast('top', msg, 'danger');
    }
    return this.modalService.confirm(valuesForm);
  }

  createStripeAcoountLink(returnl_url) {
    this.apiService.postStripeAccountLink(returnl_url).subscribe((resp) => {
      if (resp) {
        if (resp['url']) {
          //On redirige le user pour finaliser son inscription
          window.open(resp['url'], '_self');
        } else if (resp['details_submitted']) {
          //le user a deja fait son onboarding donc on fait rien
          this.sendingFormValue();
        }
      }
    });
  }
  confirm() {
    return this.modalService.confirm({});
  }
  createNewTravel() {
    //créer un nouveau voyage
    const loginData = this.datawarehouseService.currentLoginData();

    if (loginData && loginData['user']) {
      this.modalService
        .openModal(DialogTravelFormComponent, this.modalData)
        .then((resp) => {
          //récupération des données depuis le modal
          if (resp && resp['role'] == 'confirm') {
            //on récupère tous les trajets publiés
            this.getUsersTravelsPublished();
          }
        });
    } else {
      //si pas de token
      this.modalService.openModal(ModalLoginSignupComponent, {
        isLogin: false,
      });
    }
  }
  getUsersTravelsPublished() {
    //on récupère les trajets
    this.datawarehouseService.getLoginData$
      .pipe(
        delay(1000),
        switchMap((l) => {
          if (l && l['user']) {
            return this.apiService.getMyTravelsWithoutPagination('pub');
          } else {
            return of('');
          }
        })
      )
      .subscribe((t) => {
        if (t) {
          this.travelOptions = t.sort((a, b) =>
            a.travel_date < b.travel_date ? 1 : -1
          );
          if (this.travelOptions.length > 0) {
            const selectedTravel = this.travelOptions.filter(
              (tr) =>
                tr['to_city']['country'] == this.modalData['to_city']['country']
            );
            if (selectedTravel && selectedTravel.length > 0) {
              this.formTravel.get('travel').setValue(selectedTravel[0]);
              this.selected = selectedTravel[0];
            }
          }
        }
      });
  }
}
