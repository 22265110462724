import { Injectable } from '@angular/core';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  promptLabelPhoto = 'Ma galerie de photos';
  promptLabelPicture = 'Prendre une photo';
  promptLabelHeader = 'Prendre une photo';
  promptLabelCancel = 'Annuler';
  constructor(
    private translateService: TranslateService,
    private imageCompress: NgxImageCompressService
  ) {
    this.translateService.get('photo').subscribe((v) => {
      this.promptLabelPhoto = v['promptLabelPhoto'];
      this.promptLabelPicture = v['promptLabelPicture'];
      this.promptLabelHeader = v['promptLabelHeader'];
      this.promptLabelCancel = v['promptLabelCancel'];
    });
  }
  //**************utilisé dans upload file surtout pour les shoppings */
  async addNewToGallery() {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      quality: 100,
      promptLabelPhoto: this.promptLabelPhoto,
      promptLabelPicture: this.promptLabelPicture,
      promptLabelHeader: this.promptLabelHeader,
      promptLabelCancel: this.promptLabelCancel,
    });
    return await this.readAsBase64(capturedPhoto);
  }
  async addNewToGalleryForWeb() {
    // a refaire prorpement pour etre en bonne collaboration upload-file
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      quality: 100,
      promptLabelPhoto: this.promptLabelPhoto,
      promptLabelPicture: this.promptLabelPicture,
      promptLabelHeader: this.promptLabelHeader,
      promptLabelCancel: this.promptLabelCancel,
    });
    return await this.readAsBase64(capturedPhoto);
  }
  private async readAsBase64(photo: Photo) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(photo.webPath!);
    const blob = await response.blob();

    return (await this.convertBlobToBase64(blob)) as string;
  }

  private convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  //******************************utilisé uniquement  dans user-profile pour la photo de profile *********************/
  addToAvatar(isMobile:boolean = false) {
    // Take a photo
    if (isMobile) {
      return this.avataForNative()
    }else{
      return this.avataForWeb();

    }
  }
  avataForWeb() {
    // a refaire prorpement pour etre en bonne collaboration upload-file
       return this.addNewToGalleryForWeb()
         .then((image) => {
           return this.compressFile(image, -1);
         })
         .catch((e) => {
         });
  }
  avataForNative() {
    return this.addNewToGallery()
      .then((image) => {
        return this.compressFile(image, -1);
      })
      .catch((e) => {
      });
  }
  compressFile(image, orientation) {
    //voir comment rendre l'image plus nette
    return this.imageCompress.compressFile(image, orientation, 50, 50); // 50% ratio, 50% quality
  }
}
