import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { UtilsService } from 'src/app/services/utils-service/utils.service';
import { environment } from 'src/environments/environment';
import parsePhoneNumber from 'libphonenumber-js';
import { DatawarehouseService } from 'src/app/services/datawarehouse-service/datawarehouse.service';
import { ModalCountriesComponent } from '../modal-countries/modal-countries.component';
import { log } from 'console';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
})
export class SignUpFormComponent implements OnInit {
  isLogin = false;
  form: FormGroup;
  passwordForm: FormGroup;
  dataSubscription: Subscription;
  countriesdataSubscription: Subscription;
  whatsAPpBusiness = APP_CONSTANTS.whatsAPpBusiness;

  environment = environment;
  signUpMessage = 'Vous allez recevoir un mail pour activer votre compte';
  @Input() fromParentIsModal: boolean = false;
  preferredCountries = environment.preferredCountries;
  dataFromParent = 'SignUpFormComponent';
  allWorldCountries: any;

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private apiService: ApiService,
    private toastService: ToastService,
    private router: Router,
    private modalService: ModalService,
    private translateService: TranslateService,
    private datawarehouseService: DatawarehouseService
  ) {}

  ngOnInit() {
    this.getForm();
    this.translateService.get('signup.signUpMessage').subscribe((t) => {
      this.signUpMessage = t['msg1'] + '<br>' + t['msg2'];
    });
    // this.getAllCountries();
  }
  ionViewWillEnter() {
    this.getForm();
  }
  getForm() {
    this.passwordForm = this.fb.group(
      {
        password: this.fb.control('', [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(this.utilsService.pwdPattern),
          this.utilsService.noWhitespaceValidator,
        ]),
        confirmpassword: this.fb.control('', [Validators.required]),
      },
      { validator: this.utilsService.passwordMatch }
    );
    this.form = this.fb.group({
      email: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        Validators.email,
      ]),
      first_name: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        this.utilsService.noWhitespaceValidator2FirstLetter,
      ]),
      last_name: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        this.utilsService.noWhitespaceValidator2FirstLetter,
      ]),
      phone_number: this.fb.control('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      // nationality: this.fb.control('', [Validators.required]),
      passwordForm: this.passwordForm,
    });
    if (!this.environment.production) {
      this.form.get('email').setValue('aa@aa.co');
      this.form.get('first_name').setValue('aa@aa.co');
      this.form.get('last_name').setValue('aa@aa.co');
      this.form.get('phone_number').setValue('+33612345678');
      this.form.get('passwordForm.password').setValue('superTest1');
      this.form.get('passwordForm.confirmpassword').setValue('superTest1');
    }
  }
  send() {
    const value = JSON.parse(JSON.stringify(this.form.value));
    value['password'] = value['passwordForm']['password'];
    this.apiService.signUp(value).subscribe((x) => {
      if (x && x['email']) {
        if (this.fromParentIsModal) {
          this.router.navigate(['/send-ok'], {
            queryParams: { type: 'signUpModal' },
          });
          this.cancel();
        } else {
          this.router.navigate(['/send-ok'], {
            queryParams: { type: 'signUp' },
          });
        }
      }
    });
  }
  openModalCOuntries() {}
  openWhatsApp() {
    window.open(this.whatsAPpBusiness, '_blank');
  }
  cancel() {
    //pour fermer la modal
    // return this.modalService.cancel();
    return this.modalService.closeAllModal();
  }
  confirm() {
    //pour fermer la modal
    return this.modalService.confirm({});
  }
  login() {
    this.router.navigate(['/login']);
  }
  getAllCountries() {
    this.countriesdataSubscription =
      this.datawarehouseService.getallWorldCountriesData$.subscribe((c) => {
        this.allWorldCountries = c;
        if (
          this.allWorldCountries &&
          this.allWorldCountries.find((f) => f['code'] == 'FR')
        ) {
          this.form
            .get('nationality')
            .setValue(this.allWorldCountries.find((f) => f['code'] == 'FR'));
        }
      });
  }

  getErrorMessage(ctrl) {
    return this.utilsService.getErrorMessage(ctrl, this.form);
  }
  ionViewWillLeave() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.countriesdataSubscription) {
      this.countriesdataSubscription.unsubscribe();
    }
  }
}
