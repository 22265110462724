<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label>{{"kycLightTitle" | translate | capitalize}}</ion-label>
      <ion-buttons slot="end">
        <ion-button (click)="cancel()">
          <ion-icon slot="start" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>

  <ion-grid fixed class="my-container-500 ">
    <form #f="ngForm" [formGroup]="form" (ngSubmit)="send()" >
        <div [ngStyle]="{'height': '30px'}">
        
        </div>
        <ion-label *ngIf="modalData != '' && modalData != null"> Bienvenue {{modalData}}</ion-label> <br>
            <div [ngStyle]="{'height': '30px'}">
            
            </div>
        <ion-label>
          {{
          "kycLightPhone"
          | translate
          | capitalize
          }}
        </ion-label>
        <div [ngStyle]="{'height': '30px'}">
        
        </div>
        <div class="phone">
          <div [ngStyle]="{'flex-grow': '1'}">
            <mat-form-field appearance="outline" [ngStyle]="{'width': '100%'}">
              <mat-label>{{
                "account.userProfil.form.phone_number.label"
                | translate
                | capitalize
                }}</mat-label>
              <input type="tel" matInput placeholder="Ex: 0610789845" formControlName="phone_number">
              <mat-hint>{{
                "kycLightNotVisiblePhone"
                | translate
                | capitalize
                }}</mat-hint>
                <mat-error *ngIf="form.get('phone_number').invalid">{{
                  getErrorMessage("phone_number")
                  }}</mat-error>
        
            </mat-form-field>
        
          </div>
          
        
        
        
        </div>
      <div [ngStyle]="{'height': '30px'}">
    
      </div>
      <ion-button type="submit" [disabled]="form.invalid" class="w-100">
        {{ "toFinalize" | translate | capitalize }}
      </ion-button>
    
    </form>
  </ion-grid>
</ion-content>
