<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-grid fixed class="my-container-500 ">

<ion-item>
  <ion-icon slot="start" name="open-outline" color="secondary" class="icon"></ion-icon>
  <ion-label>1-{{"modalSearchProductComponent.go" | translate | capitalize}} {{modalData['label']}}</ion-label>
</ion-item>
<ion-item>
  <ion-icon slot="start" name="search-outline" color="secondary" class="icon"></ion-icon>
  <ion-label>2-{{"modalSearchProductComponent.search" | translate | capitalize}}</ion-label>
</ion-item>
<ion-item>
  <ion-icon slot="start" name="link-outline" color="secondary" class="icon"></ion-icon>
  <ion-label>3-{{"modalSearchProductComponent.copy" | translate | capitalize}}</ion-label>
</ion-item>
<ion-item>
  <ion-icon slot="start" name="copy-outline" color="secondary" class="icon"></ion-icon>
  <ion-label>4-{{"modalSearchProductComponent.past" | translate | capitalize}}</ion-label>

</ion-item>
<ion-item>
  <ion-icon slot="start" name="camera-outline" color="secondary" class="icon"></ion-icon>
  <ion-label>5-{{"modalSearchProductComponent.photo" | translate | capitalize}}</ion-label>

</ion-item>
<div [ngStyle]="{'height': '15px'}">

</div>
<div >
  <ion-button expand="block" (click)="go()">{{"okay" | translate | capitalize}}</ion-button>

</div>


  </ion-grid>
</ion-content>
