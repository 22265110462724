<ion-header mode="ios" translucent="true">
      <ion-toolbar>
            <ion-buttons slot="end">
                  <ion-button (click)="cancel()">
                        <ion-icon slot="start" name="close"></ion-icon>
                  </ion-button>
            </ion-buttons>
      </ion-toolbar>

</ion-header>
<div [ngStyle]="{'height': '100%'}">
            <app-travel [fromDialogParent]="'DialogTravelFormComponent'"         (formEvent)="formEvent($event)"
            [dataTravelItem]="dataTravelItem"
></app-travel>

      <ion-content>
      </ion-content>


</div>