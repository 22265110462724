<ion-header mode="ios" translucent="true">
  <ion-toolbar>
    <ion-label *ngIf="modalData['from'] == 'shopper'">Faites 30% d'économies sans frais cachés</ion-label>
    <ion-label *ngIf="modalData['from'] == 'traveler'">Gagnez de l'argent en voyageant</ion-label>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content color="light" *ngIf="modalData['from'] == 'shopper'">

  <ion-list [inset]="true">
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Vous avez fait le plus dur</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Rassurez-vous, vous pouvez poster votre commande <span id="gratos">gratuitement</span>
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Nous vous informons</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Dès qu'un voyageur se propose de vous ramener votre commande, vous êtes immédiatement informés par mail.<br>
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Je paie quand alors!</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Si vous acceptez l'offre du voyageur, alors dans ce cas il faudra payer pour valider l'offre.<br>
          Votre argent sera bloqué jusqu'à ce que vous recevez votre commande.         
          Vous pouvez  refuser ou accepter l'offre d'un  voyageur.
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Vous voyez</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          C'est simple et surtout votre argent est en sécurité<br>
          <span id="gratos">Maintenant, postez votre commande gratuitement!</span>
        </ion-note>
      </ion-label>
    </ion-item>

  </ion-list>
</ion-content>
<ion-content color="light" *ngIf="modalData['from'] == 'traveler'">
  <ion-list [inset]="true">
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Vous avez fait le plus dur</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Envoyez une offre à l'acheteur <span id="gratos">gratuitement</span> en définissant votre récompense
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Nous vous informons</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Dès que l'acheteur acepte ou refuse votre offre.<br>
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">ET après!</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Si l'acheteur a validé votre offre, cela veut dire qu'il(elle) a déjà payé(e).<br>
          A vous maintenant d'acheter la commande(cela vous permet de savoir exactement ce que vous transportez).<br>
          Mais ne vous inquiétez pas, car nous avons déjà bloqué l'argent.
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Vous êtes payés</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Livrez la commande et vous êtes payés(prix de la commande + récompense)<br>
        </ion-note>
      </ion-label>
    </ion-item>
    <ion-item [button]="true" detail="false">
      <div class="unread-indicator-wrapper" slot="start">
        <div class="unread-indicator"></div>
      </div>
      <ion-label>
        <ion-text class="ion-text-wrap">Hum... je reflechis</ion-text><br />
        <ion-note color="medium" class="ion-text-wrap">
          Si le prix de la commande est inférieure à 30 euros, nous pouvons vous faire une avance des frais.<br>
          Contactez-nous via whatApp 
        </ion-note><ion-icon slot="primary" (click)="openWhatsAppTraveler()" name="logo-whatsapp" size="large" class="whatsapp-color"
          slot="end"></ion-icon>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
  <ion-button expand="block" (click)="confirm()">{{"okay" | translate | capitalize}}</ion-button>
  <ion-button  (click)="openWhatsAppTraveler()" color="success" fill="outline" *ngIf="modalData['from'] == 'traveler'">
    contactez-nous
    <ion-icon slot="success"  name="logo-whatsapp" size="large" class="whatsapp-color"
      slot="end"></ion-icon>
  </ion-button>
  <ion-button (click)="openWhatsAppShopper()" color="success" fill="outline" *ngIf="modalData['from'] == 'shopper'">
    commandez
    <ion-icon slot="success" name="logo-whatsapp" size="large" class="whatsapp-color" slot="end"></ion-icon>
  </ion-button>

