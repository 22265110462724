import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APP_CONSTANTS } from './constants/app.constants';
import { DIAL_CODE_COUNTRIES } from './constants/dial_code';
import { AnalyticsService } from './services/analytics-service/analytics.service';
import { ApiService } from './services/api-service/api.service';
import { DatawarehouseService } from './services/datawarehouse-service/datawarehouse.service';
import { LocalStorageService } from './services/local-storage-service/local-storage.service';
import { ToastService } from './services/toast-service/toast.service';
import posthog from 'posthog-js';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { ActionSheetController } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = APP_CONSTANTS.title;
  default_language = APP_CONSTANTS.default_language;
  world_counrties =
    '../assets/world-countries/countries/' +
    this.default_language +
    '/world.json';
  world_counrties_flags = '../../../../assets/world-countries/flags/';
  dial_code_counrties = DIAL_CODE_COUNTRIES;
  fgsColor: any;
  cookieMsg = this.translateService.instant('cookie');
  newVersionSubscription: Subscription;
      isNewVersionAvailable: boolean = false;

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private renderer: Renderer2,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private http: HttpClient,
    private datawarehouseService: DatawarehouseService,
    private metaService: Meta,
    private router: Router,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private swUpdate: SwUpdate,
    private actionSheetCtrl: ActionSheetController
  ) {
    this.initializeApp();
    this.translateService.get('application.meta').subscribe((t) => {
      this.titleService.setTitle(this.title + ' | ' + t['title']);
      this.metaService.updateTag({
        name: 'description',
        content: t['description'],
      });
    });
    this.localStorageService.init();
    this.getWorlCountries();
    this.disableConsoleInProduction();
    this.posthog();
    this.checkForUpdate();
    
  }
  ngOnInit() {
    this.cookie();
  }
  initializeApp() {
    this.translateService.setDefaultLang(this.default_language);
    this.analyticsService.initFb();
  }
  checkForUpdate(){
    console.log("toto");
    
   this.newVersionSubscription?.unsubscribe();
        if (!this.swUpdate.isEnabled) {
            return;
        }
        this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {          
            switch (evt.type) {
                case 'VERSION_DETECTED':
                            this.presentActionSheet().then((resp) => {
                              if (resp && resp == 'yes') {
                                location.reload();
                              }
                            });

                    break;
                case 'VERSION_READY':
                    this.isNewVersionAvailable = true;
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    break;
            }
        });
  }
  async presentActionSheet() {
    //pour prévenir le user qu'une nouvelle version vient d'arriver
    const actionSheet = await this.actionSheetCtrl.create({
      header:
        'Une nouvelle version est disponible. Souhaitez-vous mettre à jour?',
      buttons: [
        {
          text: 'Oui',
          role: 'yes',
          data: {
            action: 'yes',
          },
        },
        {
          text: 'Non',
          role: 'no',

          data: {
            action: 'non',
          },
        },
      ],
    });

    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    return role;
  }
  
  posthog() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
      }
    });
  }
  getCurrentUser() {
    //pour vérifier si le user est tjrs connecté(on envoie une requete au back pour savoir si ilest encore connecté)
    //utilisé pour savoir si on doit le considerer comme connecté
    this.localStorageService.get('token').subscribe((t) => {
      if (t && t['access']) {
        this.apiService.getCurrentUserWithToken(false).subscribe((u) => {});
      }
    });
  }

  getWorlCountries() {
    //pour éviter de faire trop de requete
    this.http.get(this.world_counrties).subscribe((c: any) => {
      c.map((i) => {
        i['code'] = i['alpha2'].toUpperCase();
        i['flag'] = this.world_counrties_flags + i['alpha2'] + '.png'; //ce flag est pour user-profile car le chemin lui est spécique
        i['flag_countries_available']; //utilisé dans travel-form car chemin différents
        const dial_code = this.dial_code_counrties.find(
          (d) => d.code == i['code']
        );
        if (this.dial_code_counrties.find((d) => d.code == i['code'])) {
          i['dial_code'] = dial_code['dial_code'];
        }
        return i;
      });
      this.datawarehouseService.setallWorldCountriesData(c);
    });
  }

  cookie() {
    this.localStorageService.get('cookie').subscribe((val) => {
      if (val == null) {
        this.toastService.presentToastCookie(
          'bottom',
          this.cookieMsg,
          'medium'
        );
      }
    });
  }

  ngAfterViewInit(): void {
    const loader = this.renderer.selectRootElement('.app-loading', true);
    if (loader.style.display !== 'none') {
      loader.style.display = 'none';
    }
    this.router.events
      .pipe(
        filter((e: RouterEvent) => e instanceof NavigationEnd),
        first()
      )
      .subscribe((e: RouterEvent) => {
        //Pour faire une requete au back pour récupérer les infos du user si on a un token stocké.
        //pour éviter des conflits de requetes, on l'execute uniquement si on est pas dans account car account fait la même chose également
        if (!e.url.includes('account')) {
          this.getCurrentUser();
        } else {
        }
      });
  }
  disableConsoleInProduction(): void {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void {};
      console.debug = function (): void {};
      console.warn = function (): void {};
      console.info = function (): void {};
    }
  }
  ngOnDestroy() {
    if (this.newVersionSubscription) {
      this.newVersionSubscription.unsubscribe();
      
    }
  }
}
