<div class="ion-text-end">
  <ion-button (click)="uploadFile()" fill="outline" *ngIf="images.length == 0">{{"photo.addPhoto" | translate }}</ion-button>
</div>
<div class="back-upload " [ngClass]="images.length == 0  ? 'center-label' : ''">
  <ion-row *ngIf="images && images.length > 0">
    <ion-col offset="2"></ion-col>
    <ion-col size="6">
      <div class="ion-text-end">
        <ion-icon name="close-circle-outline" size="large" (click)="deleteFile(0)"></ion-icon>
      </div>
      <img src="{{images[0].image}}" class="img" [appPhotoMissing]="'no-image.png'" />
    </ion-col>
    <ion-col offset="2"></ion-col>
  </ion-row>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="images.length == 0 ">
    <ion-label >

      {{label}}<span class="mat-form-field-required-marker">*</span>
    </ion-label>
  </div>
</div>