import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  modalData; //cela vient du modal voir le modalService
  form: FormGroup;
  constructor(
    private modalService: ModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.init();
  }
  init() {
    this.form = this.fb.group({
      rating: this.fb.control(0, [Validators.required, Validators.min(1)]),
      comments: this.fb.control('', [
        Validators.required,
        Validators.maxLength(150),
      ]),
    });
  }
  onSubmit() {
    const delivery_uuid = this.modalData['delivery_uuid'];
    const rating = this.form.value['rating'];
    const comments = this.form.value['comments'];    
    if (rating != 0) {
      this.apiService
        .postReview(delivery_uuid, rating, comments)
        .subscribe((x) => {
          if (x && x['create']) {
            this.router.navigate(['account']);
           const msg = this.translateService.instant('createReview');
           this.toastService.presentToast('top', msg, 'success');
           this.confirm();
          }
        });
      
    }
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
  confirm() {
    return this.modalService.confirm({});
  }
}
