import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login-service/login.service';
import { ModalService } from 'src/app/services/modal-service/modal.service';

@Component({
  selector: 'app-modal-login-signup',
  templateUrl: './modal-login-signup.component.html',
  styleUrls: ['./modal-login-signup.component.scss'],
})
export class ModalLoginSignupComponent implements OnInit {
  isLogin: boolean = true; // cela est inversé, o aurait du l'appeler isSignUp
  modalData;
  fromParentIsModal = true;

  constructor(
    private modalService: ModalService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.isLogin = this.modalData['isLogin'];
  }
  login() {
    this.isLogin = false;
    this.loginService.setfromParentData({
      fromParentIsModal: true,
      route: {},
    });
  }
  signup() {
    this.isLogin = true;
  }
  cancel() {
    //pour fermer la modal
    return this.modalService.cancel();
  }
}
